import React from 'react'
import { FormattedMessage } from 'react-intl';

const textForInfluence = 'Ontosight® Influence is an intuitive AI engine for discovery of use-case specific researchers, investigators, and key opinion leaders for your drug development projects. Identify networks of top, second-tier, or emerging KOLs and dive deep into their always up-to-date comprehensive profiles & connections to strategize meaningful engagements. Prioritize KOLs to meet precise needs across any disease area, rare-diseases, or geography and get real-time updates on your favorite KOLs with only relevant, targeted information valuable to you. Why rely on subjective evaluation of external parties when you can objectively select the ones ‘best for you’!'

const keyFeatures = [
  'Single application catering to various therapeutic areas, indications and countries',
  'Algorithm-based output eliminates subjectivity',
  'Platform capable to identify TAEs / KOLs across multiple data assets',
  'Real-time updated data ensures latest and relevant TAEs / KOLs',
  'Visualization of network connections of KOLs and their relative influence',
  'High customizability on scoring logic to support multiple use cases',
  'Interactive and intuitive infographics and visualizations',
  'Alert notifications for favorite KOLs',
  'Worldwide heat-maps and 25 data filters'
]

const useCases = [
  `Preclinical support: Identify top KOLs working in different laboratories/institutes on different
  molecules and drug targets of your interest`,
  'Clinical support: Discover right set of investigators for your planned clinical trial',
  `Drug approval support: Identify KOLs associated with various regulatory agencies and HTA bodies,
  for New Drug Application (NDA)/Biologic License Application (BLA) filing`,
  `Drug launch support: Discover right set of KOLs associated with various
  hospitals/societies/congresses to define the right launch strategy`,
  'Post marketing support: Identify right set of HCPs working in patient care',
  'Discover top network KOLs who can diffuse your information faster',
  'Discover broker KOLsto engage and helping to connect with other top KOLs in the area of interest'
]

const PremiumForInfluence = () => (
  <div className='modal-premium' style={{ margin: '20px' }}>
    <div className="modal-header">
      <h3 className="modal-title text-center"> <span className="premium-icon" /><FormattedMessage id="You have discovered a Premium Feature" defaultMessage="You have discovered a Premium Feature" /></h3>
    </div>
    <div className="modal-body scrollbar" style={{ height: '500px', overflow: 'auto' }}>
      <div className="txt">
        <p className="text-center">You are trying to access <span className='make-bold'>Influence</span> that is not included in your Ontosight<sup>®</sup> subscription</p>
        <p className='make-bold'>What is Ontosight® Influence</p>
        <p className='make-justify shift-right' >{textForInfluence}</p>
        <p className='make-bold' style={{ margin: 0 }}>Key features</p>
        <ul className="list-points scrollbar">
          {keyFeatures.map((item, i) => (
            <li key={i} className='make-space'>
              <span className="tick-premium make-justify" />
              {item}
            </li>
          ))}
        </ul>
        <p className='make-bold'>Few examples of use cases of the platform</p>
        <ul className="list-points scrollbar">
          {useCases.map((item, i) => (
            <li key={i} className='make-space'>
              <span className="tick-premium make-justify" />
              {item}
            </li>
          ))}
        </ul>
        <div className="btn-wrap text-center">
          <a className="btn btn-primary primary btn-rounded" href="https://www.innoplexus.com/index.php/buy-now/" target="_blank" rel="noopener noreferrer"><FormattedMessage id="BUY NOW" defaultMessage="BUY NOW" /> </a>
        </div>
      </div>
    </div>
  </div>
)

export default PremiumForInfluence
