import React from 'react';
import { FormattedMessage } from 'react-intl';

const FreeFromNow = () => (
  <div className='modal-premium'>
    <div className="modal-header">
      <h3 className="modal-title text-center"> <span className="premium-icon" /><FormattedMessage id="You have discovered a Premium Feature" defaultMessage="You have discovered a Premium Feature" /></h3>
    </div>
    <div className="modal-body">
      <div className="txt">
        {/* <p className="text-center">You are trying to access a feature that is not included in your Ontosight Discover <sup>®</sup> subscription</p> */}
        <p className='text-center'>Great news! The premium feature you are using is now free for a limited time.</p>
        <p className='text-center'>Please note that in the future, it will become a paid service. We hope you enjoy this opportunity</p>
        <p className='text-center-lite'>and continue to use our platform.</p>
      </div>
    </div>
  </div>
)

export default FreeFromNow;
