import React, { Fragment, Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { FormattedMessage, injectIntl } from 'react-intl'
import { withRouter } from 'react-router-dom'
import { stringify, parse } from 'query-string'
import { logoutAction } from '../Login/logic'
import { getCookie, getRedirectUrl, ucFirst, setAnalytics, checkPermission } from '../../utils'
import { Mixpanel } from '../../utils/mixpanel';
import { setDataLang, LangSet } from '../../lang/logic'
import { SimpleDropDown } from '../../components'
import { drawerAction } from '../Drawer/logic'
import { modalAction } from '../Modal/logic'
import CustomerSupport from '../CustomerSupport'
import CountDown from '../CountDown'

const open = {
  type: 'notification',
  open: true
}

const close = {
  type: '',
  open: false
}

const backUrlCheck = url => [
  `/${process.env.current}/notifications`, `/${process.env.current}/subscriptions`, '/guidelinewatch/details', '/guidelinewatch/comparison'
].indexOf(url)

class Header extends Component {
  state = {
    lang: this.props.lang,
    langTitle: this.props.lang === 'de' ? 'Deutsch' : (this.props.lang === 'fr' ? 'French' : 'English')
  };
  // componentDidMount() {
  //   if (getCookie('accessToken') && ((checkPermission('marketplace_permission') && !checkPermission('ontosight_lite_user')) || (process.env.standaloneNotifications && checkPermission(`${process.env.current}_notification_permission`)))) {
  //     this.props.notificationCountAction()
  //   }
  // }

  componentWillReceiveProps = (nextProps) => {
    if (this.props.lang !== nextProps.lang) {
      this.setState({
        langTitle: nextProps.lang === 'de' ? 'Deutsch' : (nextProps.lang === 'fr' ? 'French' : 'English')
      })
    }
  };

  onLangSelection = (lang) => {
    let langCode;
    let langTitle;
    switch (lang) {
      case 'Deutsch': {
        langCode = 'de';
        langTitle = 'Deutsch';
        break;
      }
      case 'French': {
        langCode = 'fr';
        langTitle = 'French';
        break;
      }
      default: {
        langCode = 'en';
        langTitle = 'English'
      }
    }
    if (langCode !== this.state.lang) {
      this.setState(() => ({
        lang: langCode,
        langTitle
      }));
      Mixpanel.track('languageLocalizationChangeEvent', {
        eventData: JSON.stringify({
          lang: langCode,
          langTitle
        }),
      });
      setAnalytics('languageLocalizationChangeTrigger', 'languageLocalizationChange', JSON.stringify({
        lang: langCode,
        langTitle
      }))
      this.props.LangSet(langCode);
      if (langCode !== this.props.dataLang) {
        this.props.setDataLang(langCode);
      }
    }
  };

  onNotificationClick() {
    if (process.env.standaloneNotifications && checkPermission(`${process.env.current}_notification_permission`)) {
      const backUrl = `${window.location.origin}${window.location.pathname}?${stringify({ ...parse(window.location.search) })}`
      const url = getRedirectUrl(process.env.current, {}, {}, false)
      const params = stringify({
        ...(backUrlCheck(this.props.location.pathname) === -1 ? { backUrl } : { backUrl: parse(this.props.location.search).backUrl || backUrl }),
        backApp: process.env.backLabel
      })
      window.location = `${url}/notifications?${params}`
    } else {
      this.props.drawerAction(this.props.drawer.open ? close : open)
    }
  }

  onMarketplaceClick(url) {
    if (checkPermission('ontosight_lite_user')) {
      this.props.modalAction({
        type: 'premium',
        open: true,
        dialog: true,
        size: 'large'
      })
      Mixpanel.track('noPermissionEvent', {
        action: 'Market place click',
        permission: 'no permission',
        url,
      });
      setAnalytics('noPermissionTrigger', 'noPermission', JSON.stringify({
        url,
        permission: 'no permission',
        view: 'Market place click'
      }))
    } else {
      window.location.href = url;
    }
  }

  openModal() {
    this.props.modalAction({
      type: 'login',
      open: true,
      dialog: true,
      size: 'medium'
    })
  }
  render() {
    const languagesOption = ['english', 'Deutsch', 'French'];
    const { intl } = this.props;
    const userOptions = [
      {
        name: <FormattedMessage id="Header.FAQ's" defaultMessage="FAQ's" />,
        title: intl.formatMessage({ id: 'Header.Frequently asked questions', defaultMessage: 'Frequently asked questions' }),
        cb: () => {
          Mixpanel.track('pageFromWhereFaqsClickedEvent', {
            eventData: this.props.location.pathname,
          });
          setAnalytics('pageFromWhereFaqsClickedTrigger', 'pageFromWhereFaqsClicked', this.props.location.pathname);
          window.location = `${getRedirectUrl('app', {}, {}, false)}faq`
        }
      },
      {
        name: <FormattedMessage id="Header.Profile" defaultMessage="Profile" />,
        title: intl.formatMessage({ id: 'Header.More about you', defaultMessage: 'More about you' }),
        cb: () => { window.location = checkPermission('marketplace_permission') && !checkPermission('ontosight_lite_user') ? `${getRedirectUrl('app', {}, {}, false)}marketplace/#/home/myprofile/` : getRedirectUrl('profile', { tab: 'info', backApp: '' }) }
      },
      {
        name: <FormattedMessage id="Header.AccountSettings" defaultMessage="Account Settings" />,
        title: intl.formatMessage({ id: 'Header.Check / Update your settings', defaultMessage: 'Check / Update your settings' }),
        cb: () => { window.location = getRedirectUrl('profile', { tab: 'settings', backApp: '' }) }
      },
      {
        name: <FormattedMessage id="Header.Subscriptions" defaultMessage="Subscriptions" />,
        cb: () => {
          const backUrl = `${window.location.origin}${window.location.pathname}?${stringify({ ...parse(window.location.search) })}`
          const url1 = getRedirectUrl(process.env.current, {}, {}, false)
          const params = stringify({
            ...(backUrlCheck(this.props.location.pathname) === -1 ? { backUrl } : { backUrl: parse(this.props.location.search).backUrl || backUrl }),
            backApp: process.env.backLabel
          })
          window.location = `${url1}/subscriptions?${params}`
        },
        title: intl.formatMessage({ id: 'Header.Check / Update your subscriptions', defaultMessage: 'Check / Update your subscriptions' }),
        condition: !!process.env.standaloneNotifications && !!checkPermission(`${process.env.current}_notification_permission`)
      },
      // { name: 'On Boarding', cb: () => { window.location = `${getRedirectUrl('app', {}, {}, false)}marketplace/#/home/onboarding/affiliations/` } },
      {
        name: <FormattedMessage id="Header.Logout" defaultMessage="Logout" />,
        title: intl.formatMessage({ id: 'Header.See you next time!', defaultMessage: 'See you next time!' }),
        cb: () => {
          Mixpanel.track('logoutEvent', {
            eventData: getCookie('userName'),
          });
          setAnalytics('logoutTrigger', 'logout', getCookie('userName'));
          this.props.logoutAction(stringify({ app: this.props.app, trialUser: !!getCookie('trialUser') }))
        }
      }
    ]
    const filteredUserOptions = userOptions.filter(opt => !('condition' in opt) || opt.condition)
    const profileIcon = `<div title='${ucFirst(getCookie('name') || 'Guest')}' class='disp-flex vcenter' ><div class='user-img'>
    <img class='user-face' src='/src/assets/images/svg/users.svg' alt='Profile' />
  </div><div class='nav-item text-elips'>${ucFirst(getCookie('name') || 'Guest')}</div></div>`
    const url = getRedirectUrl('app', {}, {}, false)
    const urlWorkspace = getRedirectUrl('workspace', {}, {}, false)
    const active = () => {
      if (process.env.current === 'workspace') {
        return 'workspace'
      } else if (this.props.location.pathname === '/boards') {
        return 'boards'
      } else if (process.env.current === 'custom') {
        return 'boards'
      } else if (process.env.current === 'medical') {
        return 'boards'
      } else if (process.env.current === 'regulatory') {
        return 'boards'
      } else if (process.env.current === 'guidelinewatch') {
        return 'boards'
      } else if (process.env.current === 'competitive') {
        return 'boards'
      } else if (process.env.current === 'competitivev1') {
        return 'boards'
      } else if (this.props.location.pathname === '/faq') {
        return ''
      }
      return 'discover'
    }
    const count = process.env.standaloneNotifications && checkPermission(`${process.env.current}_notification_permission`)
      ? this.props.notification.count.unread_count
      : this.props.count.data
    if (getCookie('accessToken')) {
      const remove = document.getElementById('header-container')
      if (remove) {
        remove.style.display = 'block'
      }
    }
    const query = parse(window.location.hash).alreadyLoggedIn
    const hash = parse(window.location.search).alreadyLoggedIn
    const style = {
      // ...(getCookie('accessToken') ? {} : {}),
      ...!getCookie('accessToken') && (query === 'true' || hash === 'true') ? { display: 'none' } : {}
    }
    return (
      <header id='header-container' style={style} className={getCookie('accessToken') ? 'header vcenter' : 'header vcenter landing-header'}>
        <div className='container vcenter'>
          <div className='header-left vcenter'>
            <div className='logo-wrap vcenter'>
              <a href={`${process.env.standalone ? getRedirectUrl(process.env.current, null, null, false) : getRedirectUrl('app', null, null, false)}`} className='logo' >{checkPermission('ontosight_lite_user') ? <i className='icon icon-logo-iplexus-academics' /> : <i className='icon icon-logo-iplexus' />}</a>
            </div>
          </div>
          <div className='header-links vcenter'>
            <ul className='nav-pointing nav'>
              {getCookie('accessToken') &&
                <Fragment>
                  {checkPermission('discover_permission') &&
                    <li className='nav-item'>
                      <a href={`${url}search`} className={active() === 'discover' ? 'nav-link active' : 'nav-link'} title={intl.formatMessage({ id: 'Header.Context based intelligent search', defaultMessage: 'Context based intelligent search across varied data sources' })}>
                        <FormattedMessage
                          id="Header.discover"
                          defaultMessage="Discover"
                        />
                      </a>
                    </li>
                  }
                  {checkPermission('myworkspace_permission') &&
                    <li className='nav-item'>
                      <a href={`${urlWorkspace}`} className={active() === 'workspace' ? 'nav-link active' : 'nav-link'} title={intl.formatMessage({ id: 'Header.Your history, saved searches', defaultMessage: 'Your history, saved searches, and more' })}>
                        <FormattedMessage
                          id="Header.myWorkspace"
                          defaultMessage="My Workspace"
                        />
                      </a>
                    </li>
                  }
                  {checkPermission('dashboard_permission') &&
                    <li className='nav-item'>
                      <a href={`${url}boards`} className={active() === 'boards' ? 'nav-link active' : 'nav-link'} title={intl.formatMessage({ id: 'Header.Deep dive with various analysis modules', defaultMessage: 'Deep dive with various analysis modules' })}>
                        <FormattedMessage
                          id="Header.dashboards"
                          defaultMessage="Dashboards"
                        />
                      </a>
                    </li>
                  }
                  {checkPermission('marketplace_permission') &&
                    <li className='nav-item'>
                      <a onClick={() => this.onMarketplaceClick(`${url}marketplace/`)} className='nav-link' role='presentation' title={intl.formatMessage({ id: 'Header.Sell and buy unpublished data', defaultMessage: 'Sell and buy unpublished data' })}>{checkPermission('ontosight_lite_user') ? <span className='card-premium' /> : null}&nbsp;
                        <FormattedMessage
                          id="Header.marketplace"
                          defaultMessage="Marketplace"
                        />
                      </a>
                    </li>
                  }
                </Fragment>
              }
            </ul>
          </div>
          <div className='header-right vcenter'>
            <ul className='nav-pointing nav user-item vcenter'>
              {getCookie('accessToken') ? (
                <Fragment>
                  {getCookie('trialUser') && (
                    <li className='nav-item'>
                      <CountDown />
                    </li>
                  )}
                  {checkPermission('language_localization') &&
                    <li className='nav-item'>
                      <SimpleDropDown
                        DropdownClass='has-arrow'
                        hoverTitle={intl.formatMessage({ id: 'Change app language', defaultMessage: 'Change app language' })}
                        options={languagesOption}
                        title={intl.formatMessage({ id: this.state.langTitle || 'abc', defaultMessage: this.state.langTitle })}
                        onSelect={index => this.onLangSelection(languagesOption[index])}
                      />
                    </li>
                  }
                  <CustomerSupport />
                  {((checkPermission('marketplace_permission') && !checkPermission('ontosight_lite_user')) || (process.env.standaloneNotifications && checkPermission(`${process.env.current}_notification_permission`))) && (
                    <li className='nav-item notification-drawer'>
                      <a title={intl.formatMessage({ id: 'Notifications', defaultMessage: 'Notifications' })} role='presentation' className='notification-icon-wrap' onClick={() => this.onNotificationClick()} >
                        <i className='notification-icon' />
                        {count > 0 && <span className='badge-count'>{count}</span>}
                      </a>
                    </li>
                  )}
                  <li className='nav-item'>
                    <SimpleDropDown
                      DropdownClass='has-arrow'
                      options={filteredUserOptions}
                      title={profileIcon}
                      onSelect={(index) => { filteredUserOptions[index].cb() }}
                    />
                  </li>
                </Fragment>
              ) : (
                <Fragment>
                  <li className='nav-item'>
                    <SimpleDropDown
                      DropdownClass='has-arrow'
                      hoverTitle={intl.formatMessage({ id: 'Change app language', defaultMessage: 'Change app language' })}
                      options={languagesOption}
                      title={this.state.langTitle}
                      onSelect={index => this.onLangSelection(languagesOption[index])}
                    />
                  </li>
                  <CustomerSupport />
                  <li className='nav-item'>
                    <a title={intl.formatMessage({ id: 'Academic access', defaultMessage: 'Academic access' })} className='bold' style={{ color: '#ffffff', marginRight: 30, fontSize: 16 }} href='/academic'><FormattedMessage id="Academic Access" defaultMessage="Academic Access" /></a>
                  </li>
                  <li role='presentation' onClick={() => this.openModal()} className='nav-item'>
                    <FormattedMessage id="LOGIN" defaultMessage="LOGIN" />
                  </li>
                </Fragment>
                )}
            </ul>
          </div>
        </div>
      </header>
    )
  }
}

Header.propTypes = {
  logoutAction: PropTypes.func.isRequired,
  drawerAction: PropTypes.func.isRequired,
  drawer: PropTypes.object.isRequired,
  // notificationCountAction: PropTypes.func.isRequired,
  count: PropTypes.object.isRequired,
  modalAction: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  notification: PropTypes.object,
  app: PropTypes.string,
  LangSet: PropTypes.func.isRequired,
  setDataLang: PropTypes.func.isRequired,
  lang: PropTypes.string.isRequired,
  dataLang: PropTypes.string.isRequired,
  // allowForceRefreshAction: PropTypes.func.isRequired,
  // blockForceRefreshAction: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired
}

Header.defaultProps = {
  notification: {},
  app: 'ontosight-lite'
}

const mapStateToProps = state => ({
  drawer: state.drawer,
  count: state.count,
  notification: state.notificationList,
  lang: state.language.lang,
  dataLang: state.language.dataLang
})

export default withRouter(connect(mapStateToProps, {
  // logoutAction, drawerAction, notificationCountAction, modalAction, setDataLang, LangSet
  logoutAction, drawerAction, modalAction, setDataLang, LangSet
  // allowForceRefreshAction, blockForceRefreshAction
})(injectIntl(Header)))
