import React, { Component } from 'react'
import { parse, stringify } from 'query-string'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { loginAction } from '../Login/logic'
import { getCookie } from '../../utils';
import { SpecialLoader } from '../../components'

const AlreadyLoggedIn = () => (BaseComponent) => {
  class AlreadyLoggedInCheck extends Component {
    constructor (props) {
      super(props)
      const query = parse(window.location.hash)
      const hash = parse(window.location.search)
      let type = 'component'
      if ((query.alreadyLoggedIn === 'true' || hash.alreadyLoggedIn === 'true') && (hash.trialUser || query.trialUser)) {
        type = 'loading'
      } else if ((query.alreadyLoggedIn === 'true' || hash.alreadyLoggedIn === 'true') && !getCookie('accessToken')) {
        type = 'loading'
      } else if (!getCookie('accessToken')) {
        // window.location.replace(`${process.env.mainAppUrl || 'https://ontosight.ai'}`)
      }
      this.state = {
        type,
        noAccess: false,
        openModal: false
      }
      this.intializeSSO()
    }
    // componentDidMount () {
    //   this.ssoLogin()
    // }
    intializeSSO = () => {
      if (sso && this.state.type === 'loading') {
        sso.config({
          base_url: process.env.sso.baseUrl,
          client_id: process.env.sso.clientId,
          window_type: 'iframe',
          response_type: 'code',
          divId: 'ssoLogin',
          lang: this.props.lang,
          silent: true
        })
      }
    }
    replaceUrl = () => {
      const { alreadyLoggedIn, trialUser, ...others } = parse(window.location.search)
      this.props.history.replace(`${stringify({ ...others })}`)
    }
    // ssoLogin = () => {
    //   const queryTrial = parse(window.location.hash).trialUser
    //   const hashTrial = parse(window.location.search).trialUser
    //   if (queryTrial || hashTrial) {
    //     removeCookies(true)
    //     this.props.loginAction({
    //       url: 'v0/users/me',
    //       auth: true,
    //       bearer: queryTrial || hashTrial,
    //       params: ''
    //     })
    //   } else if (sso && this.state.type === 'loading') {
    //     sso.login({
    //       scope: process.env.sso.scope, nosession: true, silent: true, lang: this.props.lang
    //     }, (status) => {
    //       if (status.status === 2) {
    //         // window.location.replace(`${process.env.mainAppUrl || 'https://ontosight.ai'}/discover`)
    //         this.replaceUrl()
    //         this.setState({
    //           noAccess: true,
    //           type: 'component'
    //         })
    //       } else if (status.status === 1) {
    //         // window.location.replace(`${process.env.mainAppUrl || 'https://ontosight.ai'}`)
    //         this.replaceUrl()
    //         this.setState({
    //           type: 'component',
    //           openModal: true
    //         })
    //       } else {
    //         this.props.loginAction({
    //           params: stringify({
    //             code: status.code,
    //             app
    //           })
    //         })
    //       }
    //     })
    //   }
    // }
    render () {
      if (this.state.type === 'loading') {
        const style = {
          position: 'fixed',
          top: -10000,
          left: -10000
        }
        return (
          <div>
            <div id='ssoLogin' style={style} />
            <SpecialLoader />
          </div>
        )
      }
      return <BaseComponent openModal={this.state.openModal} noAccess={this.state.noAccess} {...this.props} />
    }
  }
  AlreadyLoggedInCheck.propTypes = {
    lang: PropTypes.string,
    loginAction: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
  }
  AlreadyLoggedInCheck.defaultProps = {
    lang: 'en'
  }
  return withRouter(connect(null, { loginAction })(AlreadyLoggedInCheck))
}
export default AlreadyLoggedIn
