import React from 'react'
import { FormattedMessage } from 'react-intl';

const keyFeatures = [
  `Access to feeds from over 50 thousand sources covering theses, grants, congresses, publications, clinical trials, news, press releases, and much more
  `,
  `Clean, structured, and connected data that is updated in near real time, with flexibility to build custom applications
  `,
  'Universal JSON data format for simple plug and play integration with almost any visualization tool, or exporting to any other file formats'
]

const useCases = []

const PremiumForDataAsScience = () => (
  <div className='modal-premium' style={{ margin: '20px' }}>
    <div className="modal-header">
      <h3 className="modal-title text-center"> <span className="premium-icon" /><FormattedMessage id="You have discovered a Premium Feature" defaultMessage="You have discovered a Premium Feature" /></h3>
    </div>
    <div className="modal-body scrollbar" style={{ height: '500px', overflow: 'auto' }}>
      <div className="txt">
        <p className="text-center">You are trying to access <span className='make-bold'>Ontosight<sup>®</sup> Data as a Service</span> that is not included in your Ontosight® Lite subscription</p>
        <p className='make-justify make-bold'>Ontosight<sup>®</sup> DaaS - RESTful APIs for life science data</p>
        <p className='make-justify shift-right'>
        Ontosight<sup>®</sup> DaaS empowers data analytics teams in life sciences with what’s most important to them – structured life sciences data! Experience the power and flexibility of data in the palm of your hand with comprehensive APIs. Equipped with Innoplexus’s proprietary machine learning algorithms and life sciences ontology, these APIs are every life sciences data team’s dream. Why spend hours crawling, structuring, and aggregating data when you can use our DaaS APIs and focus on building better solutions!
        </p>
        <p className='make-justify make-bold'>Build & grow with powerful DaaS APIs</p>
        <ul className="list-points scrollbar">
          {keyFeatures.map((item, i) => (
            <li key={i} className='make-space'>
              <span className="tick-premium make-justify" />
              {item}
            </li>
          ))}
        </ul>
        <ul className="list-points scrollbar">
          {useCases.map((item, i) => (
            <li key={i} className='make-space'>
              <span className="tick-premium make-justify" />
              {item}
            </li>
          ))}
        </ul>
        <p className='make-bold'>Ontosight Dashboards : Focus on decision making rather than data accumulation</p>
        <p className='make-justify shift-right' >Ontosight® Dashboards empower teams involved at various stages of drug development with AI-enabled custom insights and updates to make strategic decisions. Let the machine do the data heavy lifting, so you can accelerate every step with deeper insights for specific objectives. Get real-time updates on patents, competitive intelligence, treatment guidelines, regulatory requirements, and medical affairs with our unique, intuitive dashboards. Why go searching everywhere when there could be a dashboard tailored to your specific needs!</p>
        <div className="btn-wrap text-center">
          <a className="btn btn-primary primary btn-rounded" href="https://www.innoplexus.com/index.php/buy-now/" target="_blank" rel="noopener noreferrer"><FormattedMessage id="BUY NOW" defaultMessage="BUY NOW" /> </a>
        </div>
      </div>
    </div>
  </div>
)

export default PremiumForDataAsScience
