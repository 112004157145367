import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import ReactHtmlParser from 'react-html-parser'
import { withRouter } from 'react-router-dom'
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux'
import Bookmark from '../../container/Bookmark'
import { ListIcons, T, AssetTag, AnnotationIcon } from '../'
import ReadMore from '../ReadMore'
import { getRedirectUrl, generateStringArray, checkPermission, setAnalytics, parseHashUrl } from '../../utils'
import { Mixpanel } from '../../utils/mixpanel';
// import { generateStringArray } from '../../utils'
import ShowMore from '../ShowMore'
import { modalAction } from '../../container/Modal/logic'

const CtList = (props) => {
  const params = {
    doc: props.data.doc_id, id: props.data['Trial ID'],
  }
  const backParams = {
  }
  const renderTags = () => {
    let tags = []
    if (props.data.Tags && props.data.Tags.length) {
      tags = generateStringArray(props.data.Tags, 'name')
    }
    return tags.length && (<ShowMore data={[{ value: tags }]} />)
  }
  const onResultsClick = (e, url) => {
    e.stopPropagation();
    props.modalAction({
      type: 'premium',
      open: true,
      dialog: true,
      size: 'large'
    })
    Mixpanel.track('noPermissionEvent', {
      action: 'CT has link click',
      permission: 'no permission',
      url,
      ...(process.env.current === 'app' && parseHashUrl(props).summaryView === '1' ? { page: 'Analytics' } : { page: process.env.backLabel }),
    });
    setAnalytics('noPermissionTrigger', 'noPermission', JSON.stringify({
      url,
      permission: 'no permission',
      view: 'CT has link click',
      ...(process.env.current === 'app' && parseHashUrl(props).summaryView === '1' ? { page: 'Analytics' } : { page: process.env.backLabel })
    }))
  }
  const onLinkClick = (e, link, trigger, variable) => {
    e.stopPropagation()
    Mixpanel.track(trigger, {
      eventData: JSON.stringify({ link, ...(process.env.current === 'app' && parseHashUrl(props).summaryView === '1' ? { page: 'Analytics' } : { page: process.env.backLabel }) }),
    });
    setAnalytics(trigger, variable, JSON.stringify({ link, ...(process.env.current === 'app' && parseHashUrl(props).summaryView === '1' ? { page: 'Analytics' } : { page: process.env.backLabel }) }))
    window.open(link)
  }
  return (
    <Fragment>
      {props.flag &&
        <div className='card-actions'>
          <Bookmark flag={props.data.bookmark} type={props.type} id={props.data.doc_id} group='Published' />
          {!checkPermission('ontosight_lite_user') && <AnnotationIcon data={props.data} />}
        </div>
      }
      <div className='card-details'>
        <div className='card-meta'>
          <AssetTag asset={props.data.type} />
          <div className='ct-status' style={props.data['Trial Status'] ? { color: props.data['Trial Status'].color } : {}} ><T data={props.data['Trial Status'] ? props.data['Trial Status'].name : ''} /></div>
          {props.data.Phase ?
            <div className='card-subtext'>
              <div>
                {props.data['Trial Status'] ? <span>&nbsp;&nbsp;|&nbsp;&nbsp;</span> : null}{props.data.Phase}
              </div>
            </div> : null
          }
        </div>
        <div className='card-meta wrap'>
          {checkPermission('ontosight_lite_user') && props.data['Study Results'] ?
            <a className='is-clickable handicon' role='presentation' onClick={e => onResultsClick(e, getRedirectUrl('clinicaltrials', { ...params, tab: 'results' }, backParams, false))}>
              <span className='card-premium' /> {props.data['Study Results']}
            </a> :
            props.data['Study Results'] ?
              <a className='is-clickable handicon' onClick={e => onLinkClick(e, getRedirectUrl('clinicaltrials', { ...params, tab: 'results' }, backParams, false), 'hasStudyResultsClickTrigger', 'hasStudyResultsClick')} role='presentation' >
                {props.data['Study Results']}
              </a> : null
          }
          {checkPermission('ontosight_lite_user') && props.data.hasMentions ?
            <a className='is-clickable handicon' role='presentation' onClick={e => onResultsClick(e, getRedirectUrl('clinicaltrials', { ...params, tab: 'mentionsinothersources' }, backParams, false))}>
              {props.data['Study Results'] ? <span style={{ color: '#4A4A4A' }}>&nbsp;&nbsp;|&nbsp;&nbsp;</span> : null} <span className='card-premium' /> {props.data.hasMentions}
            </a> :
            props.data.hasMentions ?
              <a className='is-clickable handicon' onClick={e => onLinkClick(e, getRedirectUrl('clinicaltrials', { ...params, tab: 'mentionsinothersources' }, backParams, false), 'hasMentionsClickTrigger', 'hasMentionsClick')} role='presentation' >
                {props.data['Study Results'] ? <span style={{ color: '#4A4A4A' }}>&nbsp;&nbsp;|&nbsp;&nbsp;</span> : null}{props.data.hasMentions}
              </a> : null
          }
          {checkPermission('ontosight_lite_user') && props.data['Has Additional Study Documents'] ?
            <a title='Available for trials from Clinicaltrials.gov' className='is-clickable handicon' role='presentation' onClick={e => onResultsClick(e, getRedirectUrl('clinicaltrials', { ...params, tab: 'studydocuments' }, backParams, false))}>
              {props.data['Study Results'] || props.data.hasMentions ? <span style={{ color: '#4A4A4A' }}>&nbsp;&nbsp;|&nbsp;&nbsp;</span> : null} <span className='card-premium' /> Has Additional Study Documents
            </a> :
            props.data['Has Additional Study Documents'] ?
              <a title='Available for trials from Clinicaltrials.gov' className='is-clickable handicon' onClick={e => onLinkClick(e, getRedirectUrl('clinicaltrials', { ...params, tab: 'studydocuments' }, backParams, false), 'hasAdditionalStudyDocumentsClickTrigger', 'hasAdditionalStudyDocumentsClick')} role='presentation' >
                {props.data['Study Results'] || props.data.hasMentions ? <span style={{ color: '#4A4A4A' }}>&nbsp;&nbsp;|&nbsp;&nbsp;</span> : null}Has Additional Study Documents
              </a> : null
          }
          {checkPermission('ontosight_lite_user') && props.data['Has History Changes'] ?
            <a title='Available for trials from Clinicaltrials.gov' className='is-clickable handicon' role='presentation' onClick={e => onResultsClick(e, getRedirectUrl('clinicaltrials', { ...params, tab: 'historyofchanges' }, backParams, false))}>
              {props.data['Study Results'] || props.data.hasMentions || props.data['Has Additional Study Documents'] ? <span style={{ color: '#4A4A4A' }}>&nbsp;&nbsp;|&nbsp;&nbsp;</span> : null} <span className='card-premium' /> Has History Changes
            </a> :
            props.data['Has History Changes'] ?
              <a title='Available for trials from Clinicaltrials.gov' className='is-clickable handicon' onClick={e => onLinkClick(e, getRedirectUrl('clinicaltrials', { ...params, tab: 'historyofchanges' }, backParams, false), 'hasHistoryChangesClickTrigger', 'hasHistoryChangesClick')} role='presentation' >
                {props.data['Study Results'] || props.data.hasMentions || props.data['Has Additional Study Documents'] ? <span style={{ color: '#4A4A4A' }}>&nbsp;&nbsp;|&nbsp;&nbsp;</span> : null}Has History Changes
              </a> : null
          }
        </div>
        <h3 className='card-title margin-small-top'>
          {props.data['Trial ID'] && `${props.data['Trial ID']} | `}
          <span data-test='ct-title'>{ReactHtmlParser(props.data.Title)}</span>
        </h3>
        <div className='card-text'>
          <div className='card-subtext'>
            <div className='title'><FormattedMessage id="Condition" defaultMessage="Condition" />:</div>
            <div>
              {props.data.Conditions ?
                <ReadMore type='text' limit={50}>
                  {props.data.Conditions.join(', ')}
                </ReadMore>
                : '-'
              }
            </div>
          </div>
          <div className='card-subtext'>
            <div className='title'><FormattedMessage id="Intervention" defaultMessage="Intervention" />:</div>
            <div>
              {props.data.Interventions ?
                <ReadMore type='text' limit={50}>
                  {props.data.Interventions.map(i => i.name).join(', ')}
                </ReadMore>
                : '-'
              }
            </div>
          </div>
          {props.data.Tags && props.data.Tags.length > 0 && props.showMore && (
            <div className='card-body-content' >
              <div className='sub-title'><FormattedMessage id="Ontology Tags" defaultMessage="Ontology Tags" />:</div>
              <div className='tag-list-wrap'>{renderTags()}</div>
            </div>)}
        </div>
      </div>
      {props.overlay && <ListIcons {...props} data={props.data} likeParams={props.likeParams} takeFeedback={props.takeFeedback} />}
    </Fragment>
  )
}

CtList.propTypes = {
  type: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  modalAction: PropTypes.func.isRequired,
  flag: PropTypes.bool,
  likeParams: PropTypes.object,
  takeFeedback: PropTypes.bool,
  showMore: PropTypes.bool,
  overlay: PropTypes.bool,
}

CtList.defaultProps = {
  flag: true,
  likeParams: {},
  takeFeedback: true,
  showMore: false,
  overlay: true
}

export default withRouter(connect(null, { modalAction })(CtList))
