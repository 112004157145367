import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
// import CheckAccessToken from '../../../common/container/CheckAccessToken'
import { modalAction } from '../../../common/container/Modal/logic'
import { setAnalytics, getCookie } from '../../../common/utils'

class AcademicsLanding extends Component {
  requestInvite = () => {
    setAnalytics('rollingOutInvitesClickedTrigger');
    this.props.modalAction({
      type: 'request_invite',
      open: true,
      dialog: true,
      size: 'small'
    })
  }
  render() {
    return (
      <div className='discover-academics page-layout'>
        <div className='section section-one'>
          <div className='content left-content'>
            <div className='logo-page logo-discover' />
            <div className='content-page-title'>
              Experience the Life Sciences AI. Now.
            </div>
            <div className='content-page-info'>
              We are committed to help researchers worldwide in their quest to solve the challenges in Life Sciences. We have launched our Discover Academics Access Program which offers free access of Discover Academics version to all researchers and students, affiliated with any university worldwide.
            </div>
            {!getCookie('accessToken') &&
              <div className='request-demo-btn'>
                <button onClick={this.requestInvite} type='button' className='btn-demo-req'>Access Now</button>
                <div className='itl-text'>
                  Use your University email for requesting invite.
                </div>
              </div>
            }
          </div>
          <div className='content right-content'>
            <div className='img-right-top discover-welcome-img' />
          </div>
        </div>

        <div className='section section-two key-feature'>
          <div className='content center-content'>
            <div className='content-page-title content-page-center-title'>
              Key Features
            </div>
            <div className='page-title-features'>
              Push your competitive edge with relevant data
            </div>
            <div className='content-page-info content-page-center-info'>
              <div className='key-list'>Most comprehensive life sciences ontology</div>
              <div className='key-list'>Covers over 95% of publicly available life sciences data</div>
              <div className='key-list'>Concept-based contextual search results</div>
              <div className='key-list'>Holistic and detailed deep-dive functionalities</div>
            </div>
          </div>
        </div>
        <div className='page-container'>
          <div className='section section-two' />
          <div className='section section-three section-three-one'>
            <div className='content img-content-in'>
              <div className='img-left discover-img-one' />
            </div>
            <div className='content text-content-in'>
              <div className='content-page-title content-page-in-title'>
                Research clusters
              </div>
              <div className='content-page-info'>
                Save query around search criterion as a research cluster which can be used to track progress on the selected query results.
              </div>
            </div>
          </div>

          <div className='section section-three section-three-two'>
            <div className='content text-content-in text-two'>
              <div className='content-page-title content-page-in-title'>
                Explore top concepts
              </div>
              <div className='content-page-info'>
                Discovery of top trends in indications, top trends for interventions or targets, top affiliations associated with search query, and top journals and authors from each asset class
              </div>
            </div>
            <div className='content img-content-in'>
              <div className='img-right discover-img-two' />
            </div>
          </div>

          <div className='section section-three section-three-three'>
            <div className='content img-content-in'>
              <div className='img-left discover-img-three' />
            </div>
            <div className='content text-content-in text-three'>
              <div className='content-page-title content-page-in-title'>
                Discover top KOLs
              </div>
              <div className='content-page-info'>
                Identify key opinion leaders through an interactive word cloud. Deep-dive to view author profile, research activity graph, and details of the selected author
              </div>
            </div>
          </div>

          <div className='section section-three section-three-four'>
            <div className='content text-content-in text-two'>
              <div className='content-page-title content-page-in-title'>
                Custom sort
              </div>
              <div className='content-page-info'>
                Classify relevant documents on the basis of title, abstract, author name, or full text with custom weightages for each
              </div>
            </div>
            <div className='content img-content-in'>
              <div className='img-right discover-img-four' />
            </div>
          </div>

          <div className='section section-three section-three-five'>
            <div className='content img-content-in'>
              <div className='img-left discover-img-five' />
            </div>
            <div className='content text-content-in text-three'>
              <div className='content-page-title content-page-in-title'>
                Clinical development
              </div>
              <div className='content-page-info'>
                Plan clinical trials with up-to date information on clinical development pipeline across indications, interventions, and therapies
              </div>
            </div>
          </div>

          <div className='section section-three section-three-six'>
            <div className='content text-content-in text-two'>
              <div className='content-page-title content-page-in-title'>
                Custom filters
              </div>
              <div className='content-page-info'>
                Improve relevance of search results by defining the time range in years, or sort by recency to get results from most recent years
              </div>
            </div>
            <div className='content img-content-in'>
              <div className='img-right discover-img-six' />
            </div>
          </div>
          <div className='section section-four'>
            <div className='content connect-data-img'>
              <div className='data-text-content' />
              <div className='data-text-content'>
                <div className='logo-page logo-discover logo-discover-bottom' />
                <div className='text-logo'>Transform your discovery and management of KOLs</div>
                {!getCookie('accessToken') &&
                  <div className='request-demo-btn'>
                    <button onClick={this.requestInvite} type='button' className='btn btn-primary btn-demo-req'>Access Now</button>
                    <div className='itl-text'>
                      Use your University email for requesting invite.
                    </div>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

AcademicsLanding.propTypes = {
  modalAction: PropTypes.func.isRequired,
}

export default connect(null, { modalAction })(AcademicsLanding)


// export default connect(null, { modalAction })(CheckAccessToken()(AcademicsLanding))
