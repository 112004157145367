import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import ReactHtmlParser from 'react-html-parser'
import Bookmark from '../../container/Bookmark'
import { ListIcons, AssetTag, AnnotationIcon } from '..'
import { checkPermission } from '../../utils'

const AdvisoryCommittee = props => (
  <Fragment>
    { props.flag &&
      <div className='card-actions'>
        <Bookmark flag={props.data.bookmark} type={props.type} id={props.data.doc_id} group='Regulatory Data' />
        {!checkPermission('ontosight_lite_user') && <AnnotationIcon data={props.data} />}
      </div>
    }
    <div className='card-details'>
      <div className='card-meta'>
        <AssetTag asset={props.data.type} />
        {props.data['Committe Name'] && (
          <div className='card-meta-title text-elips'>
            {props.data['Committe Name']}
          </div>
        )}
        {props.data['Published Date'] && (
          <div className='date'>
            {props.data['Published Date']}
          </div>
        )}
        {props.data['Regulatory Body'] && <div className='date padding-small-left'>{props.data['Regulatory Body']}</div>}
      </div>
      <h3 className='card-title'>
        {ReactHtmlParser(props.data.Title)}
      </h3>
    </div>
    {props.overlay && <ListIcons {...props} data={props.data} likeParams={props.likeParams} takeFeedback={props.takeFeedback} />}
  </Fragment>
)

AdvisoryCommittee.propTypes = {
  data: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  likeParams: PropTypes.object,
  flag: PropTypes.bool,
  takeFeedback: PropTypes.bool,
  showMore: PropTypes.bool,
  overlay: PropTypes.bool
}

AdvisoryCommittee.defaultProps = {
  flag: true,
  likeParams: {},
  takeFeedback: true,
  showMore: false,
  overlay: true
}

export default AdvisoryCommittee
