import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl';
import ReactHtmlParser from 'react-html-parser'
import Bookmark from '../../container/Bookmark'
import { T } from '../'
import { setAnalytics, getRedirectUrl } from '../../utils'
import { Mixpanel } from '../../utils/mixpanel';
// import ReadMore from '../ReadMore'


const DrugList = (props) => {
  const data = props
  const params = {
    doc: data.data.drug_id,
    drugtype: data.data['Highest Phase'] === 'marketed' ? 'Marketed' : 'Active Ingredient', // 'Active_Ingredient',
    q: data.data.Name,
    tab: 'indentificationapproval',
    appLabel: process.env.appLabel
  }
  const url = window.href
  // const fUrl = `${getRedirectUrl('intervention', params, url, true)}`

  return (
    <Fragment>
      {props.flag &&
        <div className='card-actions'>
          <Bookmark flag={props.data.bookmark} type='drugs' id={props.data.doc_id} group='Published' />
        </div>
      }
      <div className='card-details'>
        <div className='card-meta' style={{ marginBottom: '5px' }}>
          <div className='card-meta-title'><FormattedMessage id="Highest Phase" defaultMessage="Highest Phase" />: {props.data['Highest Phase']}</div>
        </div>
        <h3 className='card-title'>
          {ReactHtmlParser(props.data.Name)}
        </h3>
        <div className='card-text'>
          {/* <div className='card-subtext'>
            <div className='title'>Drug ID:</div>
            <div>
              <T data={props.data.drug_id} />
            </div>
          </div> */}
          <div>
            <div className='card-subtext'>
              <div className='title' style={{ paddingRight: '5px' }}><FormattedMessage id="Active ingredients" defaultMessage="Active ingredients" />:</div>
              <div style={{ marginRight: '5px' }}>
                <T data={props.data['Active ingredients'].join(', ')} />
              </div>
            </div>
            {
              props.data.authority && (
                <div className='card-subtext'>
                  <div className='title' style={{ paddingRight: '5px' }}>Regulatory Body / Geography:</div>
                  <div>
                    <T data={props.data.authority} />
                  </div>
                </div>
              )
            }
          </div>
          {/* <div className='card-subtext'>
            <div className='title'>Primary Indications:</div>
            <div>
              <ReadMore type='text' limit={50}>
                {props.data['Primary Indication']}
              </ReadMore>
              <T data={props.data['Primary Indication']} />
            </div>
          </div> */}
          <div className="sub-title" style={{ paddingBottom: '0px' }}>
            <a
              target='_blank'
              className="view-details-link"
              rel="noopener noreferrer"
              onClick={() => {
                Mixpanel.track('viewDetailsEvent', {
                  eventData: getRedirectUrl('intervention', params, url, false),
                });
                setAnalytics('viewDetailsTrigger', 'viewDetails', getRedirectUrl('intervention', params, url, false))
              }}
              href={getRedirectUrl('intervention', params, url, false)}
            >
              <FormattedMessage id="View drug Details" defaultMessage="View drug Details" />
            </a>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

DrugList.propTypes = {
  data: PropTypes.object.isRequired,
  flag: PropTypes.bool
}

DrugList.defaultProps = {
  flag: true
}

export default DrugList
