import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux';
import { ClickOutside } from 'reusable-react-components';
import { Loader, ListType } from '..';
import Abstract from '../../container/Abstract';
import { fetchAbstractClearAction } from '../../container/Abstract/logic';
import { generateHashUrl } from '../../utils'
import { deepDives } from '../../constant'

class CardList extends Component {
  constructor() {
    super();
    this.state = {
      open: false,
      data: {},
      scroll: false,
      tourStatus: false
    };
    this.openCard = this.openCard.bind(this);
    this.closeCard = this.closeCard.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.tourStatus) {
      if (nextProps.tourStatus.step === 6) {
        this.setState({
          tourStatus: true
        })
      } else {
        this.setState({
          tourStatus: false
        })
      }
    }
  }
  openCard(item, flag, structure = false) {
    if (deepDives.indexOf(item.type) > -1 && this.props.isClickable) {
      if (this.props.openIndependent) {
        const q = generateHashUrl({
          dataset: item.type,
          id: item.doc_id,
          scroll: flag,
          structure
        })
        this.props.history.push(`/abstract?${q}`)
      } else {
        this.setState({
          data: item,
          open: true,
          scroll: flag
        });
      }
    }
  }
  closeCard() {
    this.setState(
      {
        open: false
      },
      () => this.props.fetchAbstractClearAction()
    );
  }
  renderList() {
    return this.props.list.map((item, index) => (
      <div
        role="presentation"
        key={item.doc_id || item.iid}
        onClick={() => this.openCard(item)}
        className={`card ${
          deepDives.indexOf(item.type) > -1 && this.props.isClickable ? 'is-clickable card-hover' : ''
        } has-bg pre-clinical ${index === 0 ? 'joyride-card' : ''}`}
      >
        <ListType
          type={item.type}
          data={item}
          likeParams={this.props.likeParams}
          takeFeedback={this.props.takeFeedback}
          version={this.props.version}
          openCard={structure => this.openCard(item, true, structure)}
          clickCheck={deepDives}
          tourStatus={this.state.tourStatus && index === 0}
        />
      </div>
    ));
  }
  renderData() {
    const { data } = this.state;
    return (
      <Fragment>
        {this.state.open && (
          <div className="card-modal">
            <ClickOutside
              className="card-modal-content"
              onClickOutside={() => null}
            >
              <Abstract
                {...this.props}
                data={data}
                type={data.type}
                id={data.doc_id}
                onBack={() => this.closeCard()}
                scroll={this.state.scroll && data.type === 'publications'}
                structure={this.state.scroll && data.type === 'patent'}
              />
            </ClickOutside>
          </div>
        )}
        {this.renderList()}
      </Fragment>
    );
  }
  render() {
    return (
      <Loader
        loading={this.props.loading}
        error={this.props.error}
        height={this.props.height}
        noData={this.props.list.length === 0}
        message={this.props.message}
      >
        {this.renderData()}
      </Loader>
    );
  }
}

CardList.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.bool,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  likeParams: PropTypes.object,
  list: PropTypes.array.isRequired,
  fetchAbstractClearAction: PropTypes.func.isRequired,
  takeFeedback: PropTypes.bool,
  version: PropTypes.string,
  isClickable: PropTypes.bool,
  openIndependent: PropTypes.bool,
  toId: PropTypes.string,
  id: PropTypes.string,
  history: PropTypes.object.isRequired,
  message: PropTypes.string,
  tourStatus: PropTypes.object.isRequired
};

CardList.defaultProps = {
  loading: false,
  error: false,
  height: '70vh',
  likeParams: {},
  takeFeedback: true,
  version: 'v1',
  isClickable: true,
  openIndependent: false,
  toId: 'abstract-citation-list',
  id: 'abstract-modal-id-special',
  message: 'Data unavailable'
};

export default withRouter(connect(null, { fetchAbstractClearAction })(CardList));
