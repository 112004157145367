import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl';
import ReactHtmlParser from 'react-html-parser'
// import Bookmark from '../../container/Bookmark'
import { T } from '../'
import { setAnalytics, getRedirectUrl } from '../../utils'
import { Mixpanel } from '../../utils/mixpanel';
import ReadMore from '../ReadMore'


const Drugs = props => (
  <Fragment>
    {props.flag &&
      <div className='card-actions' style={{ padding: '3px 5px 6px 5px' }}>
        {/* <Bookmark flag={props.data.bookmark} type='investigational_drugs' id={props.data.doc_id} group='Enterprise data' /> */}
      </div>
    }
    <div className='card-details'>
      <div className='card-meta' style={{ marginBottom: '5px' }}>
        <div className='card-meta-title'><FormattedMessage id="Highest Phase" defaultMessage="Highest Phase" />: {props.data['Highest Phase']}</div>
      </div>
      <h3 className='card-title'>
        {ReactHtmlParser(props.data.Name)}
      </h3>
      <div className='card-text'>
        <div className='card-subtext'>
          <div className='title'><FormattedMessage id="Drug ID" defaultMessage="Drug ID" />:</div>
          <div>
            <T data={props.data['Drug ID']} />
          </div>
        </div>
        <div className='card-subtext'>
          <div className='title'><FormattedMessage id="Last Modified" defaultMessage="Last Modified" />:</div>
          <div>
            <T data={props.data['Last Modified']} />
          </div>
        </div>
        <div className='card-subtext'>
          <div className='title'><FormattedMessage id="Primary Indications" defaultMessage="Primary Indications" />:</div>
          <div>
            <ReadMore type='text' limit={50}>
              {props.data['Primary Indication']}
            </ReadMore>
            {/* <T data={props.data['Primary Indication']} /> */}
          </div>
        </div>
        <div className="sub-title" style={{ paddingBottom: '0px' }}>
          <a
            target='_blank'
            className="view-details-link"
            rel="noopener noreferrer"
            onClick={() => {
              Mixpanel.track('viewDetailsEvent', {
                eventData: getRedirectUrl('clarivate', { doc: props.data['Drug ID'], q: props.data.Name }),
              });
              setAnalytics('viewDetailsTrigger', 'viewDetails', getRedirectUrl('clarivate', { doc: props.data['Drug ID'], q: props.data.Name }))
            }}
            href={getRedirectUrl('clarivate', { doc: props.data['Drug ID'], q: props.data.Name }, {}, false)}
          >
            <FormattedMessage id="View drug Details" defaultMessage="View drug Details" />
          </a>
        </div>
      </div>
    </div>
  </Fragment>
)

Drugs.propTypes = {
  data: PropTypes.object.isRequired,
  flag: PropTypes.bool
}

Drugs.defaultProps = {
  flag: true
}

export default Drugs
