import React from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { FormattedMessage, injectIntl } from 'react-intl';
import { parse } from 'query-string'
import { connect } from 'react-redux'
import ReactHtmlParser from 'react-html-parser'
import { getRedirectUrl, setAnalytics, checkPermission } from '../../../common/utils'
import { Mixpanel } from '../../../common/utils/mixpanel';
import { ReadMore, T } from '../'
import { modalAction } from '../../container/Modal/logic';

const CtList = (props) => {
  const { intl } = props
  const query = parse(window.location.hash)
  const hash = parse(window.location.search)
  const noBack = query.noBack || hash.noBack
  const appLabel = query.appLabel || hash.appLabel
  const params = {
    doc: props.list.doc_id,
    id: props.list['Trial ID'],
    ...props.urlParams,
    ...(noBack ? { backApp: 'Abstract' } : {}),
    ...(appLabel ? { appLabel } : {})
  }
  const backParams = {
    from: props.list.from,
    noOfItems: props.list.noOfItems
  }
  const abstract = props.data['Study Summary'].replace(/<(?!span)(?!\/)/g, '< ')

  const openmodal = (url) => {
    props.modalAction({
      type: 'premium',
      open: true,
      dialog: true,
      size: 'large'
    })
    Mixpanel.track('noPermissionEvent', {
      action: 'View CT Details click',
      permission: 'no permission',
      url,
    });
    setAnalytics('noPermissionTrigger', 'noPermission', JSON.stringify({
      url,
      permission: 'no permission',
      view: 'View CT Details click'
    }))
  }


  return (
    <div className='card-body-content'>
      <div className='sub-title' >
        {!checkPermission('clinical_trial_view_detail') ?
          <a className='view-details-link' title={intl.formatMessage({ id: 'View Details', defaultMessage: 'View Details' })} rel='noopener noreferrer' role='presentation' onClick={() => openmodal(getRedirectUrl('clinicaltrials', params, backParams))} ><span className='card-premium' />&nbsp;<FormattedMessage id="View Details" defaultMessage="View Details" /></a> :
          <a
            target='_blank'
            data-test='ct-view-details'
            className='view-details-link'
            title={intl.formatMessage({ id: 'View Details', defaultMessage: 'View Details' })}
            rel='noopener noreferrer'
            onClick={() => {
              Mixpanel.track('ctLinkEvent', {
                eventData: getRedirectUrl('clinicaltrials', params, backParams),
              });
              setAnalytics('ctLinkTrigger', 'ctLink', getRedirectUrl('clinicaltrials', params, backParams))
            }}
            href={getRedirectUrl('clinicaltrials', params, backParams, false)}
          >
            <FormattedMessage id="View Details" defaultMessage="View Details" />
          </a>
        }
      </div>
      {props.data['Study Summary'] && (
        <div className='card-body-content' >
          <div className='sub-title' ><FormattedMessage id="Abstract" defaultMessage="Abstract" /></div>
          <div data-test='ct-abstract' id='annotation-highlight-1' >{ReactHtmlParser(abstract)}</div>
        </div>)}
      <div className='card-table wrap card-table-wrap'>
        {('Trial Snapshot' in props.data) ? (
          <div data-test='ct-snapshot' className='card-table'>
            <div className='cell-item'>
              <div className='cell-title'><FormattedMessage id="Enrollment Goal" defaultMessage="Enrollment Goal" /></div>
              <div className='cell-desc'>
                <ReadMore type='text' limit={50}>
                  {props.data['Trial Snapshot']['Enrollment Goal']}
                </ReadMore>
              </div>
            </div>

            <div className='cell-item'>
              <div className='cell-title'><FormattedMessage id="Study Dates" defaultMessage="Study Dates" /></div>
              <div className='cell-desc disp-flex'>
                {props.data['Trial Snapshot']['Study Dates'][0] && <span>{props.data['Trial Snapshot']['Study Dates'][0]}</span>}
                {!!props.data['Trial Snapshot']['Study Dates'][0] && !!props.data['Trial Snapshot']['Study Dates'][1] && <span className='date-separator'><FormattedMessage id="to" defaultMessage="to" /></span>}
                {props.data['Trial Snapshot']['Study Dates'][1] && <span>{props.data['Trial Snapshot']['Study Dates'][1]}</span>}
              </div>
            </div>

            <div className='cell-item'>
              <div className='cell-title'><FormattedMessage id="Phase" defaultMessage="Phase" /></div>
              <div className='cell-desc'><T data={props.data['Trial Snapshot'].Phase} /></div>
            </div>

            <div className='cell-item'>
              <div className='cell-title'><FormattedMessage id="Condition(s)" defaultMessage="Condition(s)" /></div>
              <div className='cell-desc'>
                <ReadMore type='text' limit={50}>
                  {props.data['Trial Snapshot']['Condition(s)'].join(', ')}
                </ReadMore>
              </div>
            </div>

            <div className='cell-item'>
              <div className='cell-title'><FormattedMessage id="Study Intervention(s)" defaultMessage="Study Intervention(s)" /></div>
              <div className='cell-desc'>
                <ReadMore type='text' limit={50}>
                  {props.data['Trial Snapshot']['Study Intervention(s)'].join(', ')}
                </ReadMore>
              </div>
            </div>

            <div className='cell-item'>
              <div className='cell-title'><FormattedMessage id="Sponsor" defaultMessage="Sponsor" /></div>
              <div className='cell-desc'>
                <ReadMore type='text' limit={50}>
                  {props.data['Trial Snapshot'].Sponsor}
                </ReadMore>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  )
}

CtList.propTypes = {
  data: PropTypes.object.isRequired,
  list: PropTypes.object.isRequired,
  modalAction: PropTypes.func.isRequired,
  urlParams: PropTypes.object,
  intl: PropTypes.object.isRequired,
}
CtList.defaultProps = {
  urlParams: {}
}

export default withRouter(connect(null, { modalAction })(injectIntl(CtList)));
