import React, { Component } from 'react';
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { setLoginValuesAction } from './logic';

class SignIn extends Component {
  constructor(props) {
    super(props)
    this.state = {
      userEmail: '',
      validEmail: false,
      passwordInput: {
        password: '',
        confirmPassword: ''
      },
      passwordError: '',
      // dissableSubmitButton: false
    }
  }

  validateEmail = (event) => {
    event.preventDefault();
    this.setState({
      userEmail: event.target.value.trim()
    })
    const email = event.target.value.trim();
    const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (emailRegex.test(email)) {
      this.setState({
        validEmail: false
      })
    } else {
      this.setState({
        validEmail: true
      })
    }
    event.stopPropagation();
  };

  validatePassowrd = (password) => {
    const uppercaseRegExp = /(?=.*?[A-Z])/;
    const lowercaseRegExp = /(?=.*?[a-z])/;
    const digitsRegExp = /(?=.*?[0-9])/;
    const specialCharRegExp = /(?=.*?[#?!@$%^&*-])/;
    const minLengthRegExp = /.{8,}/;

    const notEmpty = password.length > 0;
    const uppercasePassword = uppercaseRegExp.test(password);
    const lowercasePassword = lowercaseRegExp.test(password);
    const digitsPassword = digitsRegExp.test(password);
    const specialCharPassword = specialCharRegExp.test(password);
    const minLengthPassword = minLengthRegExp.test(password);

    let errorMessage = '';

    // eslint-disable-next-line max-len
    const passwordCondition = !notEmpty || !uppercasePassword || !lowercasePassword || !digitsPassword || !specialCharPassword || !minLengthPassword;

    if (passwordCondition) {
      errorMessage = 'Password should be between 8 and 20 characters long and should contain atleast one capital letter, one small letter, one number and @!#$%^&';
    }

    this.setState({
      passwordError: errorMessage
    })
  };

  handlePassword = (event) => {
    event.preventDefault();
    this.setState({
      passwordInput: {
        ...this.state.passwordInput,
        password: event.target.value.trim()
      }
    })
    this.validatePassowrd(event.target.value.trim());
    event.stopPropagation();
  };

  handlesubmit = (event) => {
    event.preventDefault();
    this.props.setLoginValuesAction({
      email: this.state.userEmail,
      password: this.state.passwordInput.password
    })
    event.stopPropagation();
  }

  render() {
    return (
      <div className="box-for-transition">
        <div className="sign-in-up-buttons">
          <div className="sign-up-header">Sign In</div>
        </div>
        <div className="singup-box">
          <form className="signup-form" onSubmit={this.handlesubmit}>
            <label htmlFor="ontosight-signup-email" className="email-signup-form">
              <input
                type="email"
                id="ontosight-signup-email"
                placeholder="Email"
                onChange={this.validateEmail}
                value={this.state.userEmail}
                className="sign-in-up-input"
              />
              {this.state.validEmail && (
              <p className="error-message">
                <span>Please enter a valid email id.</span>
              </p>
            )}
              <div className="password-session">
                <label htmlFor="ontosight-signup-set-password">
                  <input
                    type="password"
                    id="ontosight-signup-set-password"
                    placeholder="Set password*"
                    className="sign-in-up-input"
                    value={this.state.passwordInput.password}
                    onChange={this.handlePassword}
                  />
                </label>
                {!this.state.passwordError.length ? null
                : <p className="error-message">{this.state.passwordError}</p>}
              </div>
            </label>
            <div className="button-email-not-valid">
              <button className="button-form-yellow" onClick={this.handlesubmit} type="submit">Submit</button>
            </div>
          </form>
        </div>
      </div>
    )
  }
}

SignIn.propTypes = {
  setLoginValuesAction: PropTypes.func.isRequired,
}

export default withRouter(connect(null, { setLoginValuesAction })(SignIn));
