import { Observable } from 'rxjs/Observable'
import { ajax as staticAjax } from 'rxjs/observable/dom/ajax'
import 'rxjs/add/operator/mergeMap'
import 'rxjs/add/operator/map'
import 'rxjs/add/operator/debounceTime'
import 'rxjs/add/operator/catch'
import 'rxjs/add/observable/of'
import 'rxjs/add/operator/takeUntil'
import { apiCall } from '../../../common/utils'
import { ERROR } from '../../../common/container/Status/logic'

// guided Tour trigger
const GUIDED_TOUR = 'GUIDED_TOUR'

const INITIAL_STATE = {
  lading: false,
  flag: false,
  trigger: false
}

export const guidedTourTriggerAction = () => ({
  type: GUIDED_TOUR
})

export const guidedTourTriggerReducer = (state = INITIAL_STATE, action = null) => {
  switch (action.type) {
    case GUIDED_TOUR: {
      return {
        loading: true,
        flag: true,
        trigger: true
      }
    }
    default:
      return state
  }
}

// Research Project Ledger
const RESEARCH_PROJECT_LEDGER = 'RESEARCH_PROJECT_LEDGER'
const RESEARCH_PROJECT_LEDGER_FAILURE = 'RESEARCH_PROJECT_LEDGER_FAILURE'
const RESEARCH_PROJECT_LEDGER_SUCCESS = 'RESEARCH_PROJECT_LEDGER_SUCCESS'

const INITAL_STATE_RESEARCH_PROJECT_LEDGER = {
  data: {},
  loading: false,
  error: false,
}

export const researchProjectLedgerAction = () => ({
  type: RESEARCH_PROJECT_LEDGER
})

const researchProjectLedgerSuccess = payload => ({
  type: RESEARCH_PROJECT_LEDGER_SUCCESS,
  payload
})

export const researchProjectLedgerEpic = action$ => action$
  .ofType(RESEARCH_PROJECT_LEDGER)
  .mergeMap(action => staticAjax(apiCall(`${process.env.faceliftApiUrl}v1/researchPoint/researchPointLedger`, 'GET', true, action.payload))
    .map(response => researchProjectLedgerSuccess(response))
    .catch(error => Observable.of({
      type: RESEARCH_PROJECT_LEDGER_FAILURE,
      payload: error
    }, {
      type: ERROR,
      payload: error
    })))

export const researchProjectLedgerReducer = (state = INITAL_STATE_RESEARCH_PROJECT_LEDGER, action = null) => {
  switch (action.type) {
    case RESEARCH_PROJECT_LEDGER: {
      return {
        ...state,
        data: {},
        loading: true,
        error: false
      }
    }
    case RESEARCH_PROJECT_LEDGER_SUCCESS: {
      return {
        ...state,
        data: action.payload.response,
        loading: false,
        error: false,
      }
    }
    case RESEARCH_PROJECT_LEDGER_FAILURE: {
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: true
      }
    }
    default:
      return state
  }
}

// get Referral Link

const GET_REFERRAL_LINK = 'GET_REFERRAL_LINK'
const GET_REFERRAL_LINK_FAILURE = 'GET_REFERRAL_LINK_FAILURE'
const GET_REFERRAL_LINK_SUCCESS = 'GET_REFERRAL_LINK_SUCCESS'

const INITAL_STATE_GET_REFERRAL_LINK = {
  data: {},
  loading: false,
  error: false,
  flag: false
}

export const gerReferralLinkAction = () => ({
  type: GET_REFERRAL_LINK
})

const gerReferralLinkSucess = payload => ({
  type: GET_REFERRAL_LINK_SUCCESS,
  payload
})

export const gerReferralLinkEpic = action$ => action$
  .ofType(GET_REFERRAL_LINK)
  .mergeMap(action => staticAjax(apiCall(`${process.env.faceliftApiUrl}v1/referral_program/referral_link`, 'GET', true, action.payload))
    .map(response => gerReferralLinkSucess(response))
    .catch(error => Observable.of({
      type: GET_REFERRAL_LINK_FAILURE,
      payload: error
    }, {
      type: ERROR,
      payload: error
    })))

export const gerReferralLinkReducer = (state = INITAL_STATE_GET_REFERRAL_LINK, action = null) => {
  switch (action.type) {
    case GET_REFERRAL_LINK: {
      return {
        ...state,
        data: {},
        loading: true,
        error: false,
        flag: false
      }
    }
    case GET_REFERRAL_LINK_SUCCESS: {
      return {
        ...state,
        data: action.payload.response.data,
        loading: false,
        error: false,
        flag: true,
      }
    }
    case GET_REFERRAL_LINK_FAILURE: {
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: true,
        flag: false
      }
    }
    default:
      return state
  }
}

// verify mail

const VERIFY_MAILS_REFERRAL = 'VERIFY_MAILS_REFERRAL'
const VERIFY_MAILS_REFERRAL_FAILURE = 'VERIFY_MAILS_REFERRAL_FAILURE'
const VERIFY_MAILS_REFERRAL_SUCCESS = 'VERIFY_MAILS_REFERRAL_SUCCESS'

const INITAL_STATE_VERIFY_MAILS_REFERRAL = {
  data: [],
  loading: false,
  error: false,
  flag: false
}

export const verifyReferallMailsAction = payload => ({
  type: VERIFY_MAILS_REFERRAL,
  payload
})

const verifyReferallMailsSuccess = payload => ({
  type: VERIFY_MAILS_REFERRAL_SUCCESS,
  payload
})

export const verifyReferallMailsEpic = action$ => action$
  .ofType(VERIFY_MAILS_REFERRAL)
  .mergeMap(action => staticAjax(apiCall(`${process.env.faceliftApiUrl}v1/referral_program/verify_email`, 'POST', true, action.payload.body))
    .map(response => verifyReferallMailsSuccess(response))
    .catch(error => Observable.of({
      type: VERIFY_MAILS_REFERRAL_FAILURE,
      payload: error
    }, {
      type: ERROR,
      payload: error
    })))

export const verifyReferallMailsReducer = (state = INITAL_STATE_VERIFY_MAILS_REFERRAL, action = null) => {
  switch (action.type) {
    case VERIFY_MAILS_REFERRAL: {
      return {
        ...state,
        data: [],
        loading: true,
        error: false,
        flag: false
      }
    }
    case VERIFY_MAILS_REFERRAL_SUCCESS: {
      return {
        ...state,
        data: action.payload.response.data,
        loading: false,
        error: false,
        flag: true,
      }
    }
    case VERIFY_MAILS_REFERRAL_FAILURE: {
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: true,
        flag: false
      }
    }
    default:
      return state
  }
}

// refer now
const REFER_NOW = 'REFER_NOW'
const REFER_NOW_FAILURE = 'REFER_NOW_FAILURE'
const REFER_NOW_SUCCESS = 'REFER_NOW_SUCCESS'

const INITAL_STATE_REFER_NOW = {
  data: {},
  loading: false,
  error: false,
  flag: false
}

export const referNowAction = payload => ({
  type: REFER_NOW,
  payload
})

const referNowSuccess = payload => ({
  type: REFER_NOW_SUCCESS,
  payload
})

export const referNowActionEpic = action$ => action$
  .ofType(REFER_NOW)
  .mergeMap(action => staticAjax(apiCall(`${process.env.faceliftApiUrl}v1/referral_program/refer_now`, 'POST', true, action.payload.body))
    .map(response => referNowSuccess(response))
    .catch(error => Observable.of({
      type: REFER_NOW_FAILURE,
      payload: error
    }, {
      type: ERROR,
      payload: error
    })))

export const referNowReducer = (state = INITAL_STATE_REFER_NOW, action = null) => {
  switch (action.type) {
    case REFER_NOW: {
      return {
        ...state,
        data: {},
        loading: true,
        error: false,
        flag: false
      }
    }
    case REFER_NOW_SUCCESS: {
      return {
        ...state,
        data: action.payload.response.data,
        loading: false,
        error: false,
        flag: true,
      }
    }
    case REFER_NOW_FAILURE: {
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: true,
        flag: false
      }
    }
    default:
      return state
  }
}
