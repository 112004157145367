import { combineReducers } from 'redux'
import { authReducer } from './Login/logic'
import { globalFilterDashboardReducer } from './GlobalFilter/logic'
import { modalReducer } from './Modal/logic'
import { statusReducer } from './Status/logic'
import { fetchAbstractReducer } from './Abstract/logic'
import { bookmarkReducer } from './Bookmark/logic'
import { bibliographyReducer } from './Bibliography/logic'
import { annotationFetchReducer } from './Annotation/logic'
import { listFetchReducer, listBottomFetchReducer, listTopFetchReducer } from './GenerateList/logic'
import { listV1BottomFetchReducer, listV1TopFetchReducer, listV1FetchReducer } from './GenerateListV1/logic'
import { viewSearchAutoCompleteReducer } from './ViewSearch/logic'
import { drawerReducer } from './Drawer/logic'
import { notificationReducer, notificationCountReducer } from './Notification/logic'
import { bookmarkFolderFetchReducer, editBookmarkReducer, deleteBookmarkReducer, createBookmarkReducer, createFolderReducer, deleteFolderReducer } from './BookmarkModal/logic'
import { bibliographyFolderFetchReducer, editBibliographyReducer, deleteBibliographyReducer, createBibliographyReducer, createBibliographyFolderReducer, deleteBibliographyFolderReducer } from './BibliographyModal/logic'
import { equalizerReducer, equalizerSaveReducer } from './Equalizer/logic'
import { tabsNavigationReducer } from './Tabs/logic'
import { groupCommonFetchReducer, groupCommonFetchV1Reducer } from './Groups/logic'
import { editableAutoCompleteCommonReducer } from './EditableSearchCommon/logic'
import { langReducer } from '../lang/logic'
import { fullAbstractReducer } from './FullAbstract/logic'
import { checkTokenReducer } from './CheckAccessToken/logic'
import { requestDemoReducer } from './RequestDemo/logic'
import { researchProjectLedgerReducer, gerReferralLinkReducer, verifyReferallMailsReducer, referNowReducer } from './UnifiedHeader/logic'

const reducers = {
  globalFilter: globalFilterDashboardReducer,
  auth: authReducer,
  modal: modalReducer,
  status: statusReducer,
  abstract: fetchAbstractReducer,
  bookmark: bookmarkReducer,
  bibliography: bibliographyReducer,
  annotationFetch: annotationFetchReducer,
  list: listFetchReducer,
  listBottom: listBottomFetchReducer,
  listTop: listTopFetchReducer,
  listV1Bottom: listV1BottomFetchReducer,
  listV1Top: listV1TopFetchReducer,
  listV1: listV1FetchReducer,
  autoCompleteOptions: viewSearchAutoCompleteReducer,
  drawer: drawerReducer,
  notification: notificationReducer,
  count: notificationCountReducer,
  bookmarkFolder: bookmarkFolderFetchReducer,
  editBookmark: editBookmarkReducer,
  deleteBookmark: deleteBookmarkReducer,
  createBookmark: createBookmarkReducer,
  createFolder: createFolderReducer,
  deleteFolder: deleteFolderReducer,
  equalizer: equalizerReducer,
  equalizerSave: equalizerSaveReducer,
  tabsNavigation: tabsNavigationReducer,
  groupCommon: groupCommonFetchReducer,
  groupCommonV1: groupCommonFetchV1Reducer,
  editableAutoCompleteCommon: editableAutoCompleteCommonReducer,
  language: langReducer,
  bibliographyFolder: bibliographyFolderFetchReducer,
  editBibliography: editBibliographyReducer,
  deleteBibliography: deleteBibliographyReducer,
  createBibliography: createBibliographyReducer,
  createBibliographyFolder: createBibliographyFolderReducer,
  deleteBibliographyFolder: deleteBibliographyFolderReducer,
  fullAbstract: fullAbstractReducer,
  checkToken: checkTokenReducer,
  requestDemo: requestDemoReducer,
  rewardPointsData: researchProjectLedgerReducer,
  referralLink: gerReferralLinkReducer,
  verifyReferallMailStatus: verifyReferallMailsReducer,
  referNowStatus: referNowReducer
}

export const rootReducer = combineReducers(reducers)

export default reducers
