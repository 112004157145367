import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl';
import ReactHtmlParser from 'react-html-parser'
import Bookmark from '../../container/Bookmark'
import { ClickableAuthors, ListIcons, AssetTag, AnnotationIcon } from '../'
import { generateStringArray, checkPermission } from '../../utils'
import ShowMore from '../ShowMore'

const Congresses = (props) => {
  const renderTags = () => {
    let tags = []
    if (props.data.Tags && props.data.Tags.length) {
      tags = generateStringArray(props.data.Tags, 'name')
    }
    return tags.length && (<ShowMore data={[{ value: tags }]} />)
  }
  return (
    <Fragment>
      { props.flag &&
        <div className='card-actions'>
          <Bookmark flag={props.data.bookmark} type={props.type} id={props.data.doc_id} group='Published' />
          {!checkPermission('ontosight_lite_user') && <AnnotationIcon data={props.data} />}
        </div>
      }
      <div className='card-details'>
        <div className='card-meta'>
          <AssetTag asset={props.data.type} />
          <div data-test='cong-title' className='card-meta-title text-elips'>{props.data['Congress Name']}</div>
          <div data-test='cong-date' className='date'>{props.data.Year}</div>
        </div>
        <h3 className='card-title'>
          {ReactHtmlParser(props.data.Title)}
        </h3>
        {props.data.Authors && props.data.Authors.length > 0 && props.data.Authors.map(author => author.author_name).join('').length > 0
        ? (
          <Fragment>
            <div className='card-text' >
              {props.showMore && <div data-test='author-title' className='sub-title'>Authors:</div>}
              <ClickableAuthors {...props} limit={3} />
            </div>
            {props.data.Tags && props.data.Tags.length > 0 && props.showMore && (
            <div className='card-body-content' >
              <div className='sub-title'><FormattedMessage id="Ontology Tags" defaultMessage="Ontology Tags" />:</div>
              <div className='tag-list-wrap'>{renderTags()}</div>
            </div>)}
          </Fragment>
        ) : null }
      </div>
      {props.overlay && <ListIcons {...props} data={props.data} likeParams={props.likeParams} takeFeedback={props.takeFeedback} />}
    </Fragment>
  )
}

Congresses.propTypes = {
  data: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  likeParams: PropTypes.object,
  flag: PropTypes.bool,
  takeFeedback: PropTypes.bool,
  showMore: PropTypes.bool,
  overlay: PropTypes.bool
}

Congresses.defaultProps = {
  flag: true,
  likeParams: {},
  takeFeedback: true,
  showMore: false,
  overlay: true
}

export default Congresses
