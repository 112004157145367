import React from 'react'
import { FormattedMessage } from 'react-intl';

const textForInfluence = 'Real-time intelligence on competitor drugs for each therapeutic area Get detailed analysis of publications, clinical trials, and regulatory approvals for that are in clinical development'

const keyFeatures = []

const useCases = []

const PremiumForCompetitiveLandscape = () => (
  <div className='modal-premium' style={{ margin: '20px' }}>
    <div className="modal-header">
      <h3 className="modal-title text-center"> <span className="premium-icon" /><FormattedMessage id="You have discovered a Premium Feature" defaultMessage="You have discovered a Premium Feature" /></h3>
    </div>
    <div className="modal-body" style={{ height: 'auto', overflow: 'auto' }}>
      <div className="txt">
        <p className="text-center">You are trying to access <span className='make-bold'>Competitive Landscape</span> that is not included in your Ontosight® Lite subscription</p>
        <p className='make-bold'>Competitive Landscape</p>
        <p className='make-justify shift-right'>{textForInfluence}</p>
        <ul className="list-points scrollbar">
          {keyFeatures.map((item, i) => (
            <li key={i} className='make-space'>
              <span className="tick-premium make-justify" />
              {item}
            </li>
          ))}
        </ul>
        <ul className="list-points scrollbar">
          {useCases.map((item, i) => (
            <li key={i} className='make-space'>
              <span className="tick-premium make-justify" />
              {item}
            </li>
          ))}
        </ul>
        <p className='make-bold'>Ontosight Dashboards : Focus on decision making rather than data accumulation</p>
        <p className='make-justify shift-right' >Ontosight® Dashboards empower teams involved at various stages of drug development with AI-enabled custom insights and updates to make strategic decisions. Let the machine do the data heavy lifting, so you can accelerate every step with deeper insights for specific objectives. Get real-time updates on patents, competitive intelligence, treatment guidelines, regulatory requirements, and medical affairs with our unique, intuitive dashboards. Why go searching everywhere when there could be a dashboard tailored to your specific needs!</p>
        <div className="btn-wrap text-center">
          <a className="btn btn-primary primary btn-rounded" href="https://www.innoplexus.com/index.php/buy-now/" target="_blank" rel="noopener noreferrer"><FormattedMessage id="BUY NOW" defaultMessage="BUY NOW" /> </a>
        </div>
      </div>
    </div>
  </div>
)

export default PremiumForCompetitiveLandscape
