
// SET LOGIN VALUES
const SET_LOGIN_VALUES = 'SET_LOGIN_VALUES';
const EMPTY_LOGIN_VALUES = 'EMPTY_LOGIN_VALUES';

const INITIAL_STATE = {
  data: {
    email: '',
    password: ''
  },
  flag: false
}

export const setLoginValuesAction = payload => ({
  type: SET_LOGIN_VALUES,
  payload
})

export const emptyLoginValuesAction = () => ({
  type: EMPTY_LOGIN_VALUES
})

export const loginValuesReducer = (state = INITIAL_STATE, action = null) => {
  switch (action.type) {
    case SET_LOGIN_VALUES: {
      return {
        ...state,
        data: action.payload
      }
    }
    case EMPTY_LOGIN_VALUES: {
      return {
        ...INITIAL_STATE
      }
    }
    default:
      return state;
  }
}
