import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { ClickOutside } from 'reusable-react-components'
import { withRouter } from 'react-router-dom'
import { getCookie, setAnalytics } from '../../utils'
import { Mixpanel } from '../../utils/mixpanel';
import { ErrorBoundary, CloseOnEscape } from '../'

class ChatBox extends Component {
  constructor (props) {
    super(props)
    this.state = {
      open: false
    }
    this.onFeedbackClick = this.onFeedbackClick.bind(this);
  }

  componentDidMount () {
    if (feedback) {
      const token = getCookie('accessToken', 'Ontosight-main') || getCookie('accessToken')
      const userid = getCookie('userName', 'Ontosight-main') || getCookie('userName')
      const firstName = getCookie('name', 'Ontosight-main') || getCookie('name')
      const lastName = getCookie('lastName', 'Ontosight-main') || getCookie('lastName') ? ` ${getCookie('lastName', 'Ontosight-main') || getCookie('lastName')}` : ''
      const obj = {
        appName: 'Ontosight-Umbrella',
        rootId: 'chatboxframe',
        token,
        userid,
        userName: `${firstName}${lastName}-discover`,
        height: '325px',
        width: '396px'
      }
      feedback.configQueryForm(obj)
    }
  }

  onFeedbackClick() {
    Mixpanel.track('pageFeedbackManagerOpenedEvent', {
      pageUrl: this.props.location.pathname,
    });
    setAnalytics('pageFeedbackManagerOpenedTrigger', 'pageFeedbackManagerOpened', this.props.location.pathname);
    this.setState({
      open: !this.state.open
    });
  }

  render () {
    const { intl } = this.props
    if (feedback) {
      return (
        <ErrorBoundary className='hide' >
          <CloseOnEscape onEscape={() => this.setState({ open: false })} >
            <ClickOutside onClickOutside={() => this.setState({ open: false })} >
              <div id='chat-box-container' className={this.state.open ? 'chat-box open' : 'chat-box'} >
                <div className='chat-container' >
                  <div role='presentation' onClick={() => this.onFeedbackClick()} className='chat-button' ><FormattedMessage id="Feedback" defaultMessage="Feedback" /></div>
                  <ErrorBoundary className='hide' >
                    <iframe id='chatboxframe' src='about:blank' title={intl.formatMessage({ id: 'demo', defaultMessage: 'demo' })} />
                  </ErrorBoundary>
                </div>
              </div>
            </ClickOutside>
          </CloseOnEscape>
        </ErrorBoundary>
      )
    }
    return null
  }
}

ChatBox.propTypes = {
  location: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired
}

export default withRouter(injectIntl(ChatBox))
