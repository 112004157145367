import React, { Component, Fragment } from 'react'
import { withRouter } from 'react-router-dom'
import auth0 from 'auth0-js';
import { connect } from 'react-redux'
import mixpanel from 'mixpanel-browser'
import PropTypes from 'prop-types'
import NotificationSystem from 'react-notification-system'
import { parse, stringify } from 'query-string'
import { getCookie, removeCookies, PATHS, setAnalytics, checkPermission, setCookie } from '../../utils'
import { statusCancel, customMessage } from './logic'
import { modalCloseAction } from '../Modal/logic'
import { ChatBox, OutsideClick, ErrorBoundary } from '../../components'
import { setDataLang, LangSet } from '../../lang/logic'
import { triggerSignInOrSignUpAction } from '../../../app/container/LiteAuth0/logic'
import { callbackAction } from '../../../app/container/LoginAuth0/logic';

class Status extends Component {
  constructor (props) {
    super(props)
    try {
      if (feedback) {
        window.feedback = feedback
      }
    } catch (error) {
      window.feedback = null
    }
  }

  webAuth = new auth0.WebAuth({
    domain: process.env.auth0.domain,
    clientID: process.env.auth0.clientId,
    audience: process.env.auth0.audience,
  });

  handleCallback(token) {
    this.props.callbackAction({
      headers: {
        authorization: `Bearer ${token}`,
      },
    })
  }

  componentDidMount() {
    if (window.location.href.includes('access_token')) {
      this.webAuth.parseHash({ hash: window.location.hash }, (err, authResult) => {
        if (err) {
          this.props.customMessage({
            message: 'Oops! There has been an issue. Re-try in some time.',
            status: 'error',
            title: 'Error'
          })
        }
        if (!authResult) {
          setCookie(false, 'pageLoadingAuth0');
          return null
        }
        setCookie(authResult.accessToken, 'accessToken')
        this.handleCallback(authResult.accessToken);
        return undefined;
      });
    }

    if (window.location.href.includes('ref_code') && window.location.pathname === '/lite') {
      if (getCookie('accessToken')) {
        this.webAuth.logout({
          returnTo: process.env.redirectToOntosight,
          clientID: process.env.auth0.clientId,
        });
        removeCookies()
        const { ref_code } = parse(this.props.location.search)
        setCookie(ref_code, 'ref_code')
      }
      const { ref_code } = parse(this.props.location.search)
      setCookie(ref_code, 'ref_code')
      window.location.href = `${process.env.auth0url}`
    }

    if (JSON.stringify(getCookie('path')) === JSON.stringify([]) && window.location.pathname !== '/requestaccess' && getCookie('allowedDomain') === 'false') {
      window.location.href = `${process.env.redirectUrl}requestaccess`
    }

    if (getCookie('userLoggedIn') && getCookie('allowedDomain') === 'true' && window.location.pathname === '/lite' && getCookie('emailVerified') === 'true') {
      window.location.href = `${process.env.redirectUrl}search`
    }

    if (!getCookie('userLoggedIn') && window.location.pathname !== '/lite' && window.location.pathname !== '/research' && window.location.pathname !== '/contactus' && !(window.location.href.includes('email_Verified'))) {
      window.location.href = process.env.auth0url
    }

    if (getCookie('emailVerified') === 'false' && getCookie('allowedDomain') === 'true' && window.location.pathname !== '/verify' && !(window.location.href.includes('email_Verified'))) {
      window.location.href = `${process.env.redirectUrl}verify`
    }
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.status.type !== this.props.status.type && nextProps.status.type !== '') {
      this.showNotification(nextProps.status)
      this.activityFlow(nextProps.status.type, nextProps)
      this.props.statusCancel()
    }
    if ((typeof (getCookie('accessToken')) === 'undefined' || !getCookie('accessToken')) && (PATHS.indexOf(nextProps.location.pathname.replace(/\/$/, '')) === -1) && nextProps.location.pathname !== '/') {
      setTimeout(() => {
        localStorage.clear()
      }, 500)
    }
    if (getCookie('accessToken')) {
      // document.body.style.overflow = 'hidden'
      document.body.className = ''
    }
  }
  checkLocalization (lang) {
    if (!checkPermission('language_localization') && lang !== 'en' && getCookie('accessToken')) {
      setTimeout(() => {
        this.props.customMessage({
          message: 'You do not have Localization permission, you will still be able to browse platform in English. Please contact for permission.',
          status: 'info',
          title: 'Info'
        })
      }, 300)
      const langCode = 'en';
      this.props.LangSet(langCode);
      this.props.setDataLang(langCode);
    }
  }
  activityFlow(value, nextProps) {
    switch (value) {
      case '401': {
        mixpanel.reset();
        setAnalytics('sessionExpiredTrigger', 'sessionExpired', `${getCookie('userName')}@@${checkPermission('ontosight_lite_user') ? `academics${getCookie('org') ? `##${getCookie('org')}` : ''}` : getCookie('org') || 'individual'}`)
        removeCookies()
        window.location.href = `${process.env.auth0url}`
        break
      }
      case '403': {
        break
      }
      case 'login': {
        mixpanel.identify(`${getCookie('userName')}@@${checkPermission('ontosight_lite_user') ? `academics${getCookie('org') ? `##${getCookie('org')}` : ''}` : getCookie('org') || 'individual'}`);
        setAnalytics('userIdTrigger', 'userId', `${getCookie('userName')}@@${checkPermission('ontosight_lite_user') ? `academics${getCookie('org') ? `##${getCookie('org')}` : ''}` : getCookie('org') || 'individual'}`)
        this.props.modalCloseAction()
        this.checkLocalization(nextProps.lang)
        const {
          alreadyLoggedIn, hashCode, code, trialUser, ...others
        } = parse(this.props.location.search)
        if (process.env.current === 'app') {
          if (checkPermission('discover_permission')) {
            const redirectRoute = parse(this.props.location.search).redirect_url ||
              `/search?${stringify({ ...others })}`
            this.props.history.replace(redirectRoute)
          } else {
            const redirectRoute = parse(this.props.location.search).redirect_url ||
              `${process.env.redirectRoute}?${stringify({ ...others })}`
            this.props.history.replace(redirectRoute)
          }
          // else if (checkPermission('myworkspace_permission')) {
          //   if (parse(this.props.location.search).redirect_url) {
          //     const redirectRoute = parse(this.props.location.search).redirect_url
          //     this.props.history.replace(redirectRoute)
          //   } else {
          //     window.location.replace(`${getRedirectUrl('workspace', { ...others }, {}, false)}`)
          //   }
          // }
        } else {
          const redirectRoute = parse(this.props.location.search).redirect_url ||
            `${process.env.redirectRoute}?${stringify({ ...others })}`
          this.props.history.replace(redirectRoute)
        }
        break
      }
      case 'trial-expiry': {
        localStorage.clear()
        // removeCookies()
        window.location.href = `${process.env.mainAppUrl}/logout`
        break
      }
      case 'logout': {
        // removeCookies()
        localStorage.clear()
        // window.location.href = process.env.auth0url
        break
      }
      case 'deleteAccount': {
        // removeCookies()
        break
      }
      default:
        break
    }
  }
  handleClickOutside() {
    if ((typeof (getCookie('accessToken')) === 'undefined' || !getCookie('accessToken')) && (PATHS.indexOf(this.props.location.pathname.replace(/\/$/, '')) === -1) && this.props.location.pathname !== '/') {
      if (process.env.standalone) {
        // setFavicons()
      } else {
        setTimeout(() => {
          // window.location.href = process.env.mainAppUrl
        }, 300)
      }
    }
  }
  showNotification(value) {
    const {
      title, message, status, hideNotification,
    } = value
    if (!hideNotification) {
      this.statusMessage.addNotification({
        title,
        message,
        level: status,
        autoDismiss: 4,
        position: 'tc'
      })
    }
  }
  render() {
    return (
      <Fragment>
        <NotificationSystem ref={(ref) => { this.statusMessage = ref }} />
        <OutsideClick onClickOutside={() => this.handleClickOutside()} />
        {getCookie('accessToken') && window.feedback && (
          <ErrorBoundary className='hide'>
            <ChatBox />
          </ErrorBoundary>
        )}
      </Fragment>
    )
  }
}

Status.propTypes = {
  status: PropTypes.shape({
    message: PropTypes.string,
    status: PropTypes.string,
    type: PropTypes.string,
    title: PropTypes.string
  }),
  statusCancel: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string.isRequired
  }).isRequired,
  // notificationCountAction: PropTypes.func.isRequired,
  modalCloseAction: PropTypes.func.isRequired,
  setDataLang: PropTypes.func.isRequired,
  LangSet: PropTypes.func.isRequired,
  customMessage: PropTypes.func.isRequired,
  callbackAction: PropTypes.func.isRequired
}

Status.defaultProps = {
  status: {
    message: '',
    status: '',
    type: '',
    title: ''
  }
}

const mapStateToProps = state => ({
  status: state.status,
  lang: state.language.lang
})

export default withRouter(connect(mapStateToProps, {
  statusCancel, modalCloseAction, setDataLang, LangSet, customMessage, triggerSignInOrSignUpAction, callbackAction
})(Status))
