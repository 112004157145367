import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import ReactHtmlParser from 'react-html-parser'
import Bookmark from '../../container/Bookmark'
import { ListIcons, AssetTag, AnnotationIcon } from '../'
import { checkPermission } from '../../utils'

const ExpressionData = props => (
  <Fragment>
    { props.flag &&
      <div className='card-actions'>
        <Bookmark flag={props.data.bookmark} type={props.type} id={props.data.doc_id} group='Experimental Data' />
        {!checkPermission('ontosight_lite_user') && <AnnotationIcon data={props.data} />}
      </div>
    }
    <div className='card-details'>
      <div className='card-meta'>
        <AssetTag asset={props.data.type} />
        <div data-test='cong-title' className='card-meta-title text-elips'>{props.data['Accession Number']}</div>
        <div data-test='cong-date' className='date'>{props.data['Release Date']}</div>
      </div>
      <h3 className='card-title'>
        {ReactHtmlParser(props.data.Title)}
      </h3>
    </div>
    {props.overlay && <ListIcons {...props} data={props.data} likeParams={props.likeParams} takeFeedback={props.takeFeedback} />}
  </Fragment>
)

ExpressionData.propTypes = {
  data: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  likeParams: PropTypes.object,
  flag: PropTypes.bool,
  takeFeedback: PropTypes.bool,
  showMore: PropTypes.bool,
  overlay: PropTypes.bool
}

ExpressionData.defaultProps = {
  flag: true,
  likeParams: {},
  takeFeedback: true,
  showMore: false,
  overlay: true
}

export default ExpressionData
