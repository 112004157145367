import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import ReactHtmlParser from 'react-html-parser'
import Bookmark from '../../container/Bookmark'
import { ListIcons, AssetTag, AnnotationIcon } from '../'
import { readableDate, setAnalytics, checkPermission } from '../../utils'
import { Mixpanel } from '../../utils/mixpanel';

const Deals = props => (
  <Fragment>
    { props.flag &&
      <div className='card-actions'>
        <Bookmark flag={props.data.bookmark} type={props.type} id={props.data.doc_id} group='Published' />
        {!checkPermission('ontosight_lite_user') && <AnnotationIcon data={props.data} />}
      </div>
    }
    <div className='card-details'>
      <div className='card-meta'>

        <AssetTag asset={props.data.type} />
        {props.data.Date && <div className='date'>{readableDate(props.data.Date)}</div>}
      </div>
      <h3 data-test='deals-title' className='card-title'>
        {ReactHtmlParser(props.data.Title)}
      </h3>
    </div>
    {props.data['Source Link'] && !props.overlay && (
      <a
        title={props.intl.formatMessage({ id: 'Source link', defaultMessage: 'Source link' })}
        onClick={(e) => {
          Mixpanel.track('sourceLinkEvent', {
            eventData: props.data['Source Link'].match(/^https?:/) ? props.data['Source Link'] : `http://${props.data['Source Link']}`,
          });
          setAnalytics('sourceLinkTrigger', 'sourceLink', props.data['Source Link'].match(/^https?:/) ? props.data['Source Link'] : `http://${props.data['Source Link']}`);
          e.stopPropagation()
        }}
        rel="noopener noreferrer"
        className='web-link'
        target='_blank'
        href={props.data['Source Link']}
      />
    )}
    {props.overlay && <ListIcons {...props} data={props.data} likeParams={props.likeParams} takeFeedback={props.takeFeedback} />}
  </Fragment>
)

Deals.propTypes = {
  data: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  flag: PropTypes.bool,
  likeParams: PropTypes.object,
  overlay: PropTypes.bool,
  takeFeedback: PropTypes.bool,
  intl: PropTypes.object.isRequired
}

Deals.defaultProps = {
  flag: true,
  overlay: true,
  likeParams: {},
  takeFeedback: true
}

export default injectIntl(Deals)
