import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { FormattedMessage, injectIntl } from 'react-intl';
import PropTypes from 'prop-types'
import Slider from 'react-rangeslider'
import { stringify } from 'query-string'
import { connect } from 'react-redux'
import { parseHashUrl, generateHashUrl, isJsonString, setAnalytics, j } from '../../utils'
import { Mixpanel } from '../../utils/mixpanel';
import { equalizerAction, equalizerSaveAction } from './logic'
import { Loader, InfoToolTip } from '../../components'
import { modalCloseAction } from '../Modal/logic'


class Equalizer extends Component {
  constructor(props) {
    super(props)
    const equalizer = isJsonString(parseHashUrl(props).seq, {})
    this.state = {
      equalizer
    }
    this.onChange = this.onChange.bind(this)
    this.onReset = this.onReset.bind(this)
    this.onApply = this.onApply.bind(this)
    this.generateUrl = this.generateUrl.bind(this)
  }
  componentWillMount() {
    this.props.equalizerAction(stringify(this.props.data))
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.eq.flag !== nextProps.eq.flag) {
      const equalizer = {}
      Object.keys(nextProps.eq.data).forEach(item => nextProps.eq.data[item].forEach((d) => {
        equalizer[d.display_name] = this.state.equalizer[d.display_name] || d.default_value
      }))
      this.setState({
        equalizer
      })
    }
  }
  onChange(value, key) {
    const equalizer = JSON.parse(JSON.stringify(this.state.equalizer))
    equalizer[key] = value
    this.setState({
      equalizer
    })
  }
  onReset() {
    const equalizer = {}
    Object.keys(this.props.eq.data).forEach(item => this.props.eq.data[item].forEach((d) => {
      equalizer[d.display_name] = d.default_value
    }))
    this.setState({
      equalizer
    })
  }
  onResetDefault() {
    this.setState({
      equalizer: {}
    }, () => {
      this.props.equalizerSaveAction({
        data: {
          query: {}
        },
        params: stringify(this.props.data)
      })
      this.generateUrl()
    })
  }
  onApply() {
    const query = j(this.props.eq.data)
    Object.keys(query).forEach(item => query[item].forEach((d, ind) => {
      query[item][ind].default_value = this.state.equalizer[d.display_name]
    }))
    this.props.equalizerSaveAction({
      data: {
        query
      },
      params: stringify(this.props.data)
    })
    this.generateUrl()
  }
  generateUrl() {
    this.props.modalCloseAction()
    const q = generateHashUrl({
      ...parseHashUrl(this.props),
      sortby: 'custom',
      seq: JSON.stringify(this.state.equalizer)
    })
    const AnalyticsObj = {
      ...parseHashUrl(this.props),
      sortby: 'custom',
      ...(Object.keys(this.state.equalizer).length ? { seq: JSON.stringify(this.state.equalizer) } : {})
    }
    Mixpanel.track('customSortAppliedEvent', {
      eventData: JSON.stringify(AnalyticsObj),
    });
    setAnalytics('customSortAppliedTrigger', 'customSortApplied', JSON.stringify(AnalyticsObj));
    this.props.history.push(`${q}`)
  }
  renderSliderItem(item) {
    return item.map((d, z) => (
      <div key={z} className='search-equalizer-item'>
        <div>{d.display_name}: {this.state.equalizer[d.display_name]}%</div>
        <Slider
          min={0}
          max={100}
          value={parseInt(this.state.equalizer[d.display_name], 10)}
          orientation='horizontal'
          onChange={value => this.onChange(value, d.display_name)}
          labels={{ 0: '0%', 100: '100%' }}
        />
      </div>
    ))
  }
  renderSlider() {
    const data = Object.keys(this.props.eq.data)
    return data.map((item, i) => (
      <div style={i !== data.length - 1 ? { borderBottom: '1px solid #979797' } : {}} key={i} className='disp-flex wrap width-full padding-lg-bottom padding-small-top' >
        {this.renderSliderItem(this.props.eq.data[item])}
      </div>
    ))
  }
  render() {
    const { intl } = this.props
    const query = j(this.props.eq.data)
    Object.keys(query).forEach(item => query[item].forEach((d, ind) => {
      query[item][ind].default_value = this.state.equalizer[d.display_name]
    }))
    const disabled = JSON.stringify(query) === JSON.stringify(this.props.eq.data)
    return (
      <div className='filter-body'>
        <Loader style={{ background: 'rgba(62, 62, 62, 0.9)', color: '#fff' }} loading={this.props.eq.loading} error={this.props.eq.error} height={280} >
          <div style={{ padding: '15px 20px 15px 15px', margin: 0 }} className='tooltip-header'>
            <FormattedMessage id="Custom Sort" defaultMessage="Custom Sort" />
            <InfoToolTip infoText={intl.formatMessage({ id: 'Re-order search results based on occurrence of query in specific fields of interest', defaultMessage: 'Re-order search results based on occurrence of query in specific fields of interest' })} type='white' zIndex='10001' />
          </div>
          <div className='tooltip-container filter-container disp-flex wrap search-literature-equalizer scrollbar'>
            {this.renderSlider()}
          </div>
          <div className='filter-buttons'>
            <button className='btn btn-border' onClick={() => this.onResetDefault()} ><FormattedMessage id="Reset to default" defaultMessage="Reset to default" /></button>
            <button disabled={disabled} className='btn btn-border' onClick={() => this.onReset()} ><FormattedMessage id="Reset to last save" defaultMessage="Reset to last save" /></button>
            <button className='btn' onClick={() => this.onApply()} ><FormattedMessage id="Apply" defaultMessage="Apply" /></button>
          </div>
        </Loader>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  eq: state.equalizer
})

Equalizer.propTypes = {
  eq: PropTypes.object.isRequired,
  equalizerAction: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  modalCloseAction: PropTypes.func.isRequired,
  equalizerSaveAction: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired
}
Equalizer.defaultProps = {
}

export default withRouter(connect(mapStateToProps, { equalizerAction, modalCloseAction, equalizerSaveAction })(injectIntl(Equalizer)))
