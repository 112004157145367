import { Observable } from 'rxjs/Observable'
import { ajax as staticAjax } from 'rxjs/observable/dom/ajax'
import 'rxjs/add/operator/mergeMap'
import 'rxjs/add/operator/map'
import 'rxjs/add/operator/catch'
import 'rxjs/add/observable/of'
import 'rxjs/add/operator/takeUntil'
import { apiCall } from '../../../common/utils'
import { ERROR } from '../../../common/container/Status/logic'

export const GROUP_FETCH = 'GROUP_FETCH'
export const GROUP_FETCH_CANCEL = 'GROUP_FETCH_CANCEL'
export const GROUP_UPDATE_FETCH = 'GROUP_UPDATE_FETCH'
export const GROUP_UPDATE_FETCH_CANCEL = 'GROUP_UPDATE_FETCH_CANCEL'
const GROUP_UPDATE_FETCH_SUCCESS = 'GROUP_UPDATE_FETCH_SUCCESS'
const GROUP_FETCH_SUCCESS = 'GROUP_FETCH_SUCCESS'
export const GROUP_FETCH_FAILURE = 'GROUP_FETCH_FAILURE'

const GROUP_FETCH_V0 = 'GROUP_FETCH_V0'
const GROUP_FETCH_V0_CANCEL = 'GROUP_FETCH_V0_CANCEL'
const GROUP_FETCH_V0_SUCCESS = 'GROUP_FETCH_V0_SUCCESS'
const GROUP_FETCH_V0_FAILURE = 'GROUP_FETCH_V0_FAILURE'

const INITIAL_STATE = {
  data: [],
  loading: false,
  error: false,
  flag: false
}

export const groupFetchAction = (payload, params = '') => ({
  type: GROUP_FETCH,
  payload,
  params
})

export const groupFetchCancelAction = payload => ({
  type: GROUP_FETCH_CANCEL,
  payload
})

export const groupUpdateFetchCancelAction = payload => ({
  type: GROUP_UPDATE_FETCH_CANCEL,
  payload
})

export const groupUpdateFetchAction = (payload, params = '') => ({
  type: GROUP_UPDATE_FETCH,
  payload,
  params
})

const groupFetchSuccess = payload => ({
  type: GROUP_FETCH_SUCCESS,
  payload
})

const groupUpdateFetchSuccess = payload => ({
  type: GROUP_UPDATE_FETCH_SUCCESS,
  payload
})

export const groupFetchV0Action = payload => ({
  type: GROUP_FETCH_V0,
  payload
})

export const groupFetchV0CancelAction = payload => ({
  type: GROUP_FETCH_V0_CANCEL,
  payload
})

const groupFetchV0Success = payload => ({
  type: GROUP_FETCH_V0_SUCCESS,
  payload
})

export const groupFetchEpic = action$ => action$
  .ofType(GROUP_FETCH)
  .mergeMap(action => staticAjax(apiCall(`${process.env.faceliftApiUrl}v1/search/count_v1?${action.params}`, 'POST', true, action.payload))
    .map(response => groupFetchSuccess(response))
    .takeUntil(action$.ofType(GROUP_FETCH_CANCEL))
    .catch(error => Observable.of({
      type: GROUP_FETCH_FAILURE,
      payload: error
    }, {
      type: ERROR,
      payload: error
    })))
export const groupFetchV0Epic = action$ => action$
  .ofType(GROUP_FETCH_V0)
  .mergeMap(action => staticAjax(apiCall(`${process.env.faceliftApiUrl}v0/search/count?${action.payload}`, 'GET', true))
    .map(response => groupFetchV0Success(response))
    .takeUntil(action$.ofType(GROUP_FETCH_V0_CANCEL))
    .catch(error => Observable.of({
      type: GROUP_FETCH_V0_FAILURE,
      payload: error
    }, {
      type: ERROR,
      payload: error
    })))

export const groupUpdateFetchEpic = action$ => action$
  .ofType(GROUP_UPDATE_FETCH)
  .mergeMap(action => staticAjax(apiCall(`${process.env.faceliftApiUrl}v1/search/count_v1?${action.params}`, 'POST', true, action.payload))
    .map(response => groupUpdateFetchSuccess(response))
    .takeUntil(action$.ofType(GROUP_UPDATE_FETCH_CANCEL))
    .catch(error => Observable.of({
      type: GROUP_FETCH_FAILURE,
      payload: error
    }, {
      type: ERROR,
      payload: error
    })))

export function groupFetchReducer(state = INITIAL_STATE, action = null) {
  switch (action.type) {
    case GROUP_FETCH: {
      return {
        ...state,
        loading: true,
        error: false,
        flag: false
      }
    }
    case GROUP_UPDATE_FETCH: {
      return {
        ...state,
        error: false,
        flag: false
      }
    }
    case GROUP_FETCH_SUCCESS: {
      const data = action.payload.response.data.filter(item => item.list && (item.list.length !== 0 || item.total !== 0))
      // data.map(item => item.list.map((a) => {
      //   if (a.value === 'publications_w') {
      //     const b = j(a)
      //     a.academics = true
      //     return b
      //   }
      //   return a
      // }))
      return {
        ...state,
        data,
        loading: false,
        error: false,
        flag: true
      }
    }
    case GROUP_UPDATE_FETCH_SUCCESS: {
      return {
        ...state,
        data: action.payload.response.data.filter(item => item.list && item.list.length !== 0 && item.total !== 0),
        flag: true,
        error: false
      }
    }
    case GROUP_FETCH_FAILURE: {
      return {
        ...state,
        data: [],
        loading: false,
        error: true,
        flag: false
      }
    }
    default:
      return state
  }
}

export function groupFetchV0Reducer(state = INITIAL_STATE, action = null) {
  switch (action.type) {
    case GROUP_FETCH_V0: {
      return {
        ...state,
        loading: true,
        error: false,
        flag: false
      }
    }
    case GROUP_FETCH_V0_SUCCESS: {
      return {
        ...state,
        data: action.payload.response.filter(item => item.list && item.list.length !== 0),
        loading: false,
        error: false,
        flag: true
      }
    }
    case GROUP_FETCH_V0_FAILURE: {
      return {
        ...state,
        data: [],
        loading: false,
        error: true,
        flag: false
      }
    }
    default:
      return state
  }
}
