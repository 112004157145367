import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import ToolTip from 'react-portal-tooltip'
import { ClickOutside } from 'reusable-react-components'
import { CloseOnEscape } from '../../../common/components'
import { style, getCookie, setAnalytics } from '../../../common/utils'
import { Mixpanel } from '../../../common/utils/mixpanel';

const support = [
  { number: '0800 180 0542', logo: 'ger-icon', title: 'GER' },
  { number: '1 800 299 4062', logo: 'us-icon', title: 'USA' },
  { number: '0800 915 420', logo: 'fr-icon', title: 'FR' },
  { number: '0800 563 632', logo: 'swz-icon', title: 'CH' },
]

class CustomerSupport extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isTooltipActive: false,
    }
    this.showTooltip = this.showTooltip.bind(this)
    this.hideTooltip = this.hideTooltip.bind(this)
  }

  showTooltip() {
    Mixpanel.track('pageFromWhichCustomerSupportAccessedEvent', {
      eventData: this.props.location.pathname,
    });
    setAnalytics('pageFromWhichCustomerSupportAccessedTrigger', 'pageFromWhichCustomerSupportAccessed', this.props.location.pathname);
    this.setState({
      isTooltipActive: !this.state.isTooltipActive,
    })
  }

  hideTooltip() {
    this.setState({ isTooltipActive: false })
  }
  render() {
    const custom = {
      style: {
        ...style.style,
        'z-index': '9000',
        ...(!getCookie('accessToken') ? {
          background: '#ffffff', border: '1px solid #c7c7c7', boxShadow: 'none', marginTop: -1, padding: 20
        } : {
          marginTop: -3, padding: 0
        })
      },
      arrowStyle: {
        ...style.arrowStyle,
        ...(!getCookie('accessToken') ? { borderBottom: 'none' } : {})
      }
    }
    const { intl } = this.props;
    return (
      <CloseOnEscape onEscape={this.hideTooltip} >
        <ClickOutside onClickOutside={() => this.hideTooltip()} >
          <div>
            <div title={intl.formatMessage({ id: 'Header.Customer Support', defaultMessage: 'Customer Support' })} ref={(element) => { this.element = element }} onClick={this.showTooltip} role='presentation' >
              <i className='support-icon' />
              {!getCookie('accessToken') && <span className='padding-small-left' >Support</span>}
            </div>
            <ToolTip style={custom} active={this.state.isTooltipActive} arrow='center' position='bottom' parent={this.state.isTooltipActive && this.element}>
              <div className={`customer-support-container ${!getCookie('accessToken') ? 'support-without-login' : ''}`} >
                {
                  support.map((item, index) => (
                    <div key={index} role="presentation" className="customer-support-item" >
                      <div className='flag-media'>
                        <i style={{ display: 'block' }} className={item.logo} /><span className='flag-title'>{item.title}</span>
                      </div>
                      <div className='flag-count'>
                        <span className='contact-number'>{item.number}</span>
                      </div>
                    </div>
                  ))
                }
              </div>
            </ToolTip>
          </div>
        </ClickOutside>
      </CloseOnEscape >
    )
  }
}

CustomerSupport.propTypes = {
  location: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired
}
CustomerSupport.defaultProps = {
}

export default withRouter(injectIntl(CustomerSupport))
