import { Observable } from 'rxjs/Observable'
import { ajax as staticAjax } from 'rxjs/observable/dom/ajax'
import 'rxjs/add/operator/mergeMap'
import 'rxjs/add/operator/map'
import 'rxjs/add/operator/debounceTime'
import 'rxjs/add/operator/catch'
import 'rxjs/add/observable/of'
import 'rxjs/add/operator/takeUntil'
import { apiCall } from '../../../common/utils'
import { ERROR } from '../../../common/container/Status/logic'

// Constants
export const EDITABLE_AUTO_COMPLETE = 'EDITABLE_AUTO_COMPLETE'
export const EDITABLE_AUTO_COMPLETE_SUCCESS = 'EDITABLE_AUTO_COMPLETE_SUCCESS'
export const EDITABLE_AUTO_COMPLETE_FAILURE = 'EDITABLE_AUTO_COMPLETE_FAILURE'
export const EDITABLE_AUTO_COMPLETE_CANCEL = 'EDITABLE_AUTO_COMPLETE_CANCEL'

const ADD_SEARCH_TERM = 'ADD_SEARCH_TERM'

const INITIAL_STATE = {
  data: {},
  loading: false,
  error: false,
  flag: false
}

// Auto Complete cancel action
export const editableAutoCompleteCancelAction = () => ({
  type: EDITABLE_AUTO_COMPLETE_CANCEL
})

// Auto Complete action
export const editableAutoCompleteAction = payload => ({
  type: EDITABLE_AUTO_COMPLETE,
  payload
})

// Auto Complete Success action
export const editableAutoCompleteSuccess = payload => ({
  type: EDITABLE_AUTO_COMPLETE_SUCCESS,
  payload
})

// Add search term action
export const addSearchTermAction = payload => ({
  type: ADD_SEARCH_TERM,
  payload
})

// Auto Complete epic
export const editableAutoCompleteEpic = action$ => action$
  .ofType(EDITABLE_AUTO_COMPLETE)
  .debounceTime(500)
  .mergeMap(action => staticAjax(apiCall(`${process.env.faceliftApiUrl}v1/search/autocomplete?${action.payload}`, 'GET'))
    .map(response => editableAutoCompleteSuccess(response))
    .takeUntil(action$.ofType(EDITABLE_AUTO_COMPLETE_CANCEL))
    .catch(error => Observable.of({
      type: EDITABLE_AUTO_COMPLETE_FAILURE,
      payload: error
    }, {
      type: ERROR,
      payload: error
    })))

// Auth reducer updates both editableAutoComplete and logout
export function editableAutoCompleteReducer (state = INITIAL_STATE, action = null) {
  switch (action.type) {
    case EDITABLE_AUTO_COMPLETE: {
      return {
        ...state,
        data: {},
        loading: true,
        error: false,
        flag: false
      }
    }
    case EDITABLE_AUTO_COMPLETE_SUCCESS: {
      return {
        ...state,
        data: action.payload.response.data.length ? action.payload.response.data[0] : {},
        loading: false,
        error: false,
        flag: true
      }
    }
    case EDITABLE_AUTO_COMPLETE_FAILURE: {
      return {
        ...state,
        data: {},
        loading: false,
        error: true,
        flag: false
      }
    }
    default:
      return state
  }
}

export function addSearchTermReducer (state = {}, action = null) {
  if (action.type === ADD_SEARCH_TERM) {
    return action.payload
  }
  return state
}


// FORCE REFRESH
export const ALLOW_FORCE_REFRESH = 'ALLOW_FORCE_REFRESH'
export const BLOCK_FORCE_REFRESH = 'BLOCK_FORCE_REFRESH'
export const FORCE_REFRESH_INITIAL_STATE = {
  allowForceRefresh: false
}

export const allowForceRefreshAction = () => ({
  type: ALLOW_FORCE_REFRESH
})

export const blockForceRefreshAction = () => ({
  type: BLOCK_FORCE_REFRESH
})

export function forceRefreshReducer (state = FORCE_REFRESH_INITIAL_STATE, action = null) {
  switch (action.type) {
    case ALLOW_FORCE_REFRESH: {
      return {
        ...state,
        allowForceRefresh: true,
      }
    }
    case BLOCK_FORCE_REFRESH: {
      return {
        ...state,
        allowForceRefresh: false,
      }
    }
    default:
      return state
  }
}


// Create Search History
// Constants
export const CREATE_HISTORY = 'CREATE_HISTORY'
export const CREATE_HISTORY_SUCCESS = 'CREATE_HISTORY_SUCCESS'
export const CREATE_HISTORY_FAILURE = 'CREATE_HISTORY_FAILURE'

export const createHistoryAction = payload => ({
  type: CREATE_HISTORY,
  payload
})

export const createHistorySuccess = payload => ({
  type: CREATE_HISTORY_SUCCESS,
  payload
})

export const createHistoryEpic = action$ => action$
  .ofType(CREATE_HISTORY)
  .mergeMap(action => staticAjax(apiCall(`${process.env.faceliftApiUrl}v1/workspace/createsearchhistory`, 'POST', true, action.payload))
    .map(response => createHistorySuccess(response))
    .catch(error => Observable.of({
      type: CREATE_HISTORY_FAILURE,
      payload: error
    }, {
      type: ERROR,
      payload: error
    })))

export function createHistoryReducer (state = INITIAL_STATE, action = null) {
  switch (action.type) {
    case CREATE_HISTORY: {
      return {
        ...state,
        data: [],
        loading: true,
        error: false,
        flag: false
      }
    }
    case CREATE_HISTORY_SUCCESS: {
      return {
        ...state,
        data: action.payload.response.data,
        loading: false,
        error: false,
        flag: true
      }
    }
    case CREATE_HISTORY_FAILURE: {
      return {
        ...state,
        data: [],
        loading: false,
        error: true,
        flag: false
      }
    }
    default:
      return state
  }
}

// Tour status
const RUN_TOUR = 'RUN_TOUR';
const STOP_TOUR = 'STOP_TOUR';
const STEP_UPDATE = 'STEP_UPDATE'

const TOUR_STATE = {
  runStatus: false,
  flag: false,
  loading: true,
  step: null
}

export const tourStepUpdateAction = payload => ({
  type: STEP_UPDATE,
  payload
})

export const runTourAction = () => ({
  type: RUN_TOUR
})

export const closeTourAction = () => ({
  type: STOP_TOUR
})

export const tourStatusReducer = (state = TOUR_STATE, action = null) => {
  switch (action.type) {
    case RUN_TOUR: {
      return {
        runStatus: true,
        flag: true,
        loading: false,
        step: 0
      }
    }
    case STOP_TOUR: {
      return {
        runStatus: false,
        flag: true,
        loading: false,
        step: null
      }
    }
    case STEP_UPDATE: {
      return {
        runStatus: true,
        flag: true,
        loading: false,
        step: action.payload.step
      }
    }
    default:
      return state;
  }
}

// Research Project Ledger
const RESEARCH_PROJECT_LEDGER = 'RESEARCH_PROJECT_LEDGER'
const RESEARCH_PROJECT_LEDGER_FAILURE = 'RESEARCH_PROJECT_LEDGER_FAILURE'
const RESEARCH_PROJECT_LEDGER_SUCCESS = 'RESEARCH_PROJECT_LEDGER_SUCCESS'

const INITAL_STATE_RESEARCH_PROJECT_LEDGER = {
  data: {},
  loading: false,
  error: false,
}

export const researchProjectLedgerActoin = () => ({
  type: RESEARCH_PROJECT_LEDGER
})

const researchProjectLedgerSuccess = payload => ({
  type: RESEARCH_PROJECT_LEDGER_SUCCESS,
  payload
})

export const researchProjectLedgerEpic = action$ => action$
  .ofType(RESEARCH_PROJECT_LEDGER)
  .mergeMap(action => staticAjax(apiCall(`${process.env.faceliftApiUrl}v1/researchPoint/researchProjectLedger`, 'GET', true, action.payload))
    .map(response => researchProjectLedgerSuccess(response))
    .catch(error => Observable.of({
      type: RESEARCH_PROJECT_LEDGER_FAILURE,
      payload: error
    }, {
      type: ERROR,
      payload: error
    })))

export const researchProjectLedgerReducer = (state = INITAL_STATE_RESEARCH_PROJECT_LEDGER, action = null) => {
  switch (action.type) {
    case RESEARCH_PROJECT_LEDGER: {
      return {
        ...state,
        data: {},
        loading: true,
        error: false
      }
    }
    case RESEARCH_PROJECT_LEDGER_SUCCESS: {
      return {
        ...state,
        data: action.payload.response,
        loading: false,
        error: false,
      }
    }
    case RESEARCH_PROJECT_LEDGER_FAILURE: {
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: true
      }
    }
    default:
      return state
  }
}


// search TotalCount
const SEARCH_TOTALCOUNT = 'SEARCH_TOTALCOUNT'
const SEARCH_TOTALCOUNT_FAILURE = 'SEARCH_TOTALCOUNT_FAILURE'
const SEARCH_TOTALCOUNT_SUCCESS = 'SEARCH_TOTALCOUNT_SUCCESS'

const INITAL_STATE_SEARCH_TOTALCOUNT = {
  data: {},
  loading: false,
  error: false,
  flag: false
}

export const searchTotalCountAction = () => ({
  type: SEARCH_TOTALCOUNT
})

const searchTotalCountSuccess = payload => ({
  type: SEARCH_TOTALCOUNT_SUCCESS,
  payload
})

export const searchTotalCountEpic = action$ => action$
  .ofType(SEARCH_TOTALCOUNT)
  .mergeMap(action => staticAjax(apiCall(`${process.env.faceliftApiUrl}v1/researchPoint/search_TotalCount`, 'GET', true, action.payload))
    .map(response => searchTotalCountSuccess(response))
    .catch(error => Observable.of({
      type: SEARCH_TOTALCOUNT_FAILURE,
      payload: error
    }, {
      type: ERROR,
      payload: error
    })))

export const searchTotalCountReducer = (state = INITAL_STATE_SEARCH_TOTALCOUNT, action = null) => {
  switch (action.type) {
    case SEARCH_TOTALCOUNT: {
      return {
        ...state,
        data: {},
        loading: true,
        error: false,
      }
    }
    case SEARCH_TOTALCOUNT_SUCCESS: {
      return {
        ...state,
        data: action.payload.response.data,
        loading: false,
        error: false,
        flag: true
      }
    }
    case SEARCH_TOTALCOUNT_FAILURE: {
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: true
      }
    }
    default:
      return state
  }
}


// update Guided tour status
const UPDATE_GUIDEDTOUR_STATUS = 'UPDATE_GUIDEDTOUR_STATUS'
const UPDATE_GUIDEDTOUR_STATUS_SUCCESS = 'UPDATE_GUIDEDTOUR_STATUS_SUCCESS'
const UPDATE_GUIDEDTOUR_STATUS_FAILURE = 'UPDATE_GUIDEDTOUR_STATUS_FAILURE'

const INITAL_STATE_GUIDED_TOUR = {
  data: {},
  loading: false,
  error: false,
}

export const updateGuidedtourStatusAction = () => ({
  type: UPDATE_GUIDEDTOUR_STATUS,
})

const updateGuidedtourStatusSuccess = payload => ({
  type: UPDATE_GUIDEDTOUR_STATUS_SUCCESS,
  payload
})

export const updateGuidedtourStatusEpic = action$ => action$
  .ofType(UPDATE_GUIDEDTOUR_STATUS)
  .mergeMap(() => staticAjax(apiCall(`${process.env.faceliftApiUrl}v0/auth/guidedtour`, 'PUT', true, {}))
    .map(response => updateGuidedtourStatusSuccess(response))
    .catch(error => Observable.of({
      type: UPDATE_GUIDEDTOUR_STATUS_FAILURE,
      payload: error
    }, {
      type: ERROR,
      payload: error
    })))

export const updateGuidedtourStatusReducer = (state = INITAL_STATE_GUIDED_TOUR, action = null) => {
  switch (action.type) {
    case UPDATE_GUIDEDTOUR_STATUS: {
      return {
        ...state,
        data: {},
        loading: true,
        error: false
      }
    }
    case UPDATE_GUIDEDTOUR_STATUS_SUCCESS: {
      return {
        ...state,
        data: action.payload.response.data,
        loading: false,
        error: false,
      }
    }
    case UPDATE_GUIDEDTOUR_STATUS_FAILURE: {
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: true
      }
    }
    default:
      return state
  }
}
