import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl';
import { setAnalytics, ucFirst } from '../../utils';
import { Mixpanel } from '../../utils/mixpanel';

const PremiumClient = ({ data }) => {
  const params = {
    wiley: {
      url: 'https://onlinelibrary.wiley.com/action/registration?sid=ontosight'
    },
    springer: {
      url: 'https://login.springer.com/public/en/login?sid=ontosight'
    }
  }
  const goTo = (link, type) => {
    if (type === 'innoplexus') {
      Mixpanel.track(`${data}InnoplexusBuyNowEvent`, {
        eventData: `Clicked Innoplexus Buy now for ${data}`,
      });
      setAnalytics(`${data}InnoplexusBuyNowTrigger`, `${data}InnoplexusBuyNow`, `Clicked Innoplexus Buy now for ${data}`)
    } else {
      Mixpanel.track(`${data}BuyNowTrigger`, {
        eventData: `Clicked ${data} Buy now for ${data}`,
      });
      setAnalytics(`${data}BuyNowTrigger`, `${data}BuyNow`, `Clicked ${data} Buy now for ${data}`)
    }
    window.open(link)
  }
  return (
    <div className='modal-premium'>
      <div className="modal-header">
        <h3 className="modal-title text-center"> <span className="premium-icon" /><FormattedMessage id="You have discovered a Premium Feature" defaultMessage="You have discovered a Premium Feature" /></h3>
      </div>
      <div className="modal-body">
        <div className='disp-flex' >
          <div style={{ flex: 1, padding: '10px 10px 0', borderRight: '1px solid #ddd' }} >
            <div data-test='purchase' className="txt text-center">
              Purchase Innoplexus subscription to
            </div>
            <div data-test='access' className="txt text-center" >access {ucFirst(data)} data.</div>
          </div>
          <div style={{ flex: 1, padding: '10px 10px 0' }} >
            <div data-test='Subscribe' className="txt text-center">
              Subscribe to {ucFirst(data)} Online Library.
            </div>
          </div>
        </div>
        <div className='disp-flex' >
          <div style={{ flex: 1, padding: '10px', borderRight: '1px solid #ddd' }} >
            <div className="btn-wrap text-center margin-medium-top">
              <a data-test='BUY NOW' onClick={() => goTo('https://www.innoplexus.com/index.php/buy-now/', 'innoplexus')} className="btn btn-primary primary btn-rounded" role='presentation'><FormattedMessage id="BUY NOW" defaultMessage="BUY NOW" /> </a>
            </div>
          </div>
          <div style={{ flex: 1, padding: '10px' }} >
            <div className="btn-wrap text-center margin-medium-top">
              <a onClick={() => goTo(params[data].url)} className="btn btn-primary primary btn-rounded" role='presentation' ><FormattedMessage id="BUY NOW" defaultMessage="BUY NOW" /> </a>
            </div>
          </div>
        </div>
        <div className='contact-us-subscription-issue'>
          <div className="txt text-center">Please reach out to us if you already have subscription</div>
          <a href={`${process.env.mainAppUrl}/contactus`} className="btn btn-primary primary btn-rounded" >Contact Us</a>
        </div>
      </div>
    </div>
  )
}

PremiumClient.propTypes = {
  data: PropTypes.string,
}

PremiumClient.defaultProps = {
  data: 'wiley'
}

export default PremiumClient
