import React, { Component } from 'react'
import RequestDemo from '../RequestDemo'

class Discover extends Component {
  componentDidMount() {
    console.log()
  }
  render() {
    return (
      <div className='discover page-layout'>
        <div className='top-landing-head'>
          <div className='section section-one'>
            <div className='content left-content'>
              <div className='logo-page logo-discover' />
              <div data-test='Speed up' className='content-page-title'>
                Speed up your research by 8x
              </div>
              <div data-test='Discover is' className='content-page-info'>
                <span data-test='onto'>Ontosight<sup>® </sup></span>
                Discover is a fully automated discovery engine using artificial intelligence to direct your focus on the data that matters most to you. Access the worlds most comprehensive scientific literature database, covering 1M theses, 5M grants, 3M congress articles, 35M publications, 580K clinical trials, 2M news & press releases and much more. Why wait for months when you can access everything in real time!
              </div>
              <RequestDemo requestApp='Discover' />
            </div>
            <div className='content right-content'>
              <div className='img-right-top discover-welcome-img' />
            </div>
          </div>

          <div className='section section-two key-feature'>
            <div className='content center-content'>
              <div data-test='Highlights' className='content-page-title content-page-center-title'>
                Highlights
              </div>
              <div data-test='Automate' className='page-title-features'>
                Automate 80% of data intelligence
              </div>
              <div className='content-page-info content-page-center-info'>
                <div data-test='AI' className='key-list'>AI-powered discovery engine to help generate customizable results and intuitive graphics from up to 95% of public life sciences data
                </div>
                <div data-test='Curated' className='key-list'>Curated using life sciences language processing™ and life sciences ontology of over 30M biomedical terms
                </div>
                <div data-test='Get 2x' className='key-list'>Get 2x more coverage than clinicaltrial.gov and 12% more than Pubmed
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='page-container'>
          <div className='section section-two' />
          <div className='section section-three section-three-one'>
            <div className='content img-content-in'>
              <div className='img-left discover-img-one' />
            </div>
            <div className='content text-content-in'>
              <div data-test='Concept-based' className='content-page-title content-page-in-title'>
                Concept-based search
              </div>
              <div data-test='Generate' className='content-page-info'>
                Generate results more relevant for you than traditional, ambiguous search engines. AI combined with life sciences ontology understands your search term, disambiguates entities, and suggests concepts. Narrow your search space using numerous filter parameters including time, source, and custom weightings to select, sort, and classify relevant documents.
              </div>
            </div>
          </div>

          <div className='section section-three section-three-two'>
            <div className='content text-content-in text-two'>
              <div data-test='Real-time' className='content-page-title content-page-in-title'>
               Real-time data update
              </div>
              <div data-test='Generate insights' className='content-page-info'>
                Generate insights based on present-day information with the help of our AI technology that can crawl over 40K webpages/second of relevant clinical and medical data. Our life science data repository is continually updated in near real time, to enable informed decisions built around the most recently published data.
              </div>
            </div>
            <div className='content img-content-in'>
              <div className='img-right discover-img-two' />
            </div>
          </div>

          <div className='section section-three section-three-three'>
            <div className='content img-content-in'>
              <div className='img-left discover-img-three' />
            </div>
            <div className='content text-content-in text-three'>
              <div data-test='Custom newsletters' className='content-page-title content-page-in-title'>
                Custom newsletters
              </div>
              <div data-test='Stay on top' className='content-page-info'>
                Stay on top of your unique research area by creating research clusters setting alerts to receive real-time updates as relevant literature, grants, and news are published. Organize bookmarks, citations, sharing, and team collaboration with a personalized workspace.
              </div>
            </div>
          </div>

          <div className='section section-three section-three-four'>
            <div className='content text-content-in text-two'>
              <div data-test='Intuitive' className='content-page-title content-page-in-title'>
                Intuitive visualization
              </div>
              <div data-test='Build insights' className='content-page-info'>
                Build insights through intuitive graphs rather than reading thousands of results. Leverage the analytics view to visually understand your search space with a quick glance on top trends, authors, and affiliations. Explore concepts (indications, interventions, or targets) related to your search query through intuitive word clouds.
              </div>
            </div>
            <div className='content img-content-in'>
              <div className='img-right discover-img-four' />
            </div>
          </div>

          <div className='section section-three section-three-five'>
            <div className='content img-content-in'>
              <div className='img-left discover-img-five' />
            </div>
            <div className='content text-content-in text-three'>
              <div data-test='Intuitive' className='content-page-title content-page-in-title'>
               Intuitive visualization
              </div>
              <div data-test='Understand' className='content-page-info'>
                Understand the mechanism of a drug, or potential indications and trials underway, with detailed information and ontology connections, or explore gene ontology, phenotypes, and synthetic lethality. Automatically creates hooks for gene, protein, drug, and researcher entities from the search query, allowing you to find detailed information and ontology connections.
              </div>
            </div>
          </div>

          <div className='section section-four'>
            <div className='content connect-data-img connect-data-img-discover'>
              <div className='img-left' />
              <div className='data-text-content'>
                <div className='logo-page logo-discover logo-discover-bottom' />
                <div data-test='Enable accurate' className='text-logo'>Enable accurate, informed decision-making with Ontosight<sup>®</sup> Discover!</div>
                <RequestDemo requestApp='Discover' />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Discover

// export default CheckAccessToken(!getCookie('accessToken'))(Discover)
