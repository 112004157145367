import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { parse } from 'query-string'
import { articleFeedbackFailure, articleFeedbackSuccess } from './logic'
import { apiCallFetch, setAnalytics, parseHashUrl } from '../../utils';
import { Mixpanel } from '../../utils/mixpanel';

class LikeDislike extends Component {
  constructor(props) {
    super(props)
    this.state = {
      like: props.data.is_like
    }
    this.likeArticle = this.likeArticle.bind(this)
    this.dislikeArticle = this.dislikeArticle.bind(this)
    this.saveFeedback = this.saveFeedback.bind(this)
  }
  saveFeedback(status) {
    const query = parse(this.props.location.search)
    const q = {
      dataset: this.props.data.type,
      doc_id: this.props.data.doc_id,
      islike: status,
      query: {
        ...(query.filters ? JSON.parse(query.filters) : {}),
        query: query.q,
        sortby: query.sortby || 'latest',
        ...(Object.keys(this.props.likeParams).length > 0 ? this.props.likeParams : {}),
      }
    }
    Mixpanel.track('likeDislikeEvent', {
      eventData: JSON.stringify({
        dataset: this.props.data.type,
        doc_id: this.props.data.doc_id,
        islike: status,
        query: {
          ...(query.filters ? JSON.parse(query.filters) : {}),
          query: query.q,
          sortby: query.sortby || 'latest',
          ...(Object.keys(this.props.likeParams).length > 0 ? this.props.likeParams : {}),
        },
        ...(process.env.current === 'app' && parseHashUrl(this.props).summaryView === '1' ? { page: 'Analytics' } : { page: process.env.backLabel })
      }),
    });
    setAnalytics('likeDislikeTrigger', 'likeDislike', JSON.stringify({
      dataset: this.props.data.type,
      doc_id: this.props.data.doc_id,
      islike: status,
      query: {
        ...(query.filters ? JSON.parse(query.filters) : {}),
        query: query.q,
        sortby: query.sortby || 'latest',
        ...(Object.keys(this.props.likeParams).length > 0 ? this.props.likeParams : {}),
      },
      ...(process.env.current === 'app' && parseHashUrl(this.props).summaryView === '1' ? { page: 'Analytics' } : { page: process.env.backLabel })
    }))
    const url = `${process.env.faceliftApiUrl}${this.props.version}/feedback/articles?app=ontosight-lite`;
    const params = apiCallFetch('POST', true, q)
    fetch(url, params)
      .then((res) => {
        if (res.ok) {
          return res
            .json()
            .then((response) => {
              this.setState({
                like: response.is_like
              }, () => this.props.articleFeedbackSuccess())
            })
        }
        return this.props.articleFeedbackFailure()
      })
      .catch(() => {
        this.props.articleFeedbackFailure()
      })
  }
  likeArticle(e) {
    e.stopPropagation()
    const status = this.state.like === 1 ? 0 : 1;
    this.saveFeedback(status)
  }
  dislikeArticle(e) {
    e.stopPropagation()
    const status = this.state.like === -1 ? 0 : -1;
    this.saveFeedback(status)
  }
  render() {
    // if (true) {
    //   return null
    // }
    return (
      <div>
        <span role='presentation' onClick={this.likeArticle} className={`icon-thumb-up icon ${this.state.like === 1 ? 'active' : ''}`} />
        <span role='presentation' onClick={this.dislikeArticle} className={`icon-thumb-down icon ${this.state.like === -1 ? 'active' : ''}`} />
      </div>
    )
  }
}

LikeDislike.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.shape({
    search: PropTypes.string
  }),
  likeParams: PropTypes.object,
  articleFeedbackFailure: PropTypes.func.isRequired,
  articleFeedbackSuccess: PropTypes.func.isRequired,
  version: PropTypes.string,
}

LikeDislike.defaultProps = {
  location: {
    search: ''
  },
  likeParams: {},
  version: 'v0'
}

export default withRouter(connect(null, { articleFeedbackFailure, articleFeedbackSuccess })(LikeDislike))
