import { Observable } from 'rxjs/Observable'
import { ajax as staticAjax } from 'rxjs/observable/dom/ajax'
import 'rxjs/add/operator/mergeMap'
import 'rxjs/add/operator/map'
import 'rxjs/add/operator/debounceTime'
import 'rxjs/add/operator/catch'
import 'rxjs/add/observable/of'
import 'rxjs/add/operator/takeUntil'
import auth0 from 'auth0-js';
import { removeCookies, apiCall } from '../../../common/utils';
import { ERROR } from '../../../common/container/Status/logic'

// trigger signIn signUp

const TRIGGER_SIGN_IN_OR_UP = 'TRIGGER_SIGN_IN_OR_UP';
const TRIGGER_SIGN_IN_OR_UP_FAILURE = 'TRIGGER_SIGN_IN_OR_UP_FAILURE';
const TRIGGER_LOGOUT = 'TRIGGER_LOGOUT';

const INITIAL_STATE = {
  trigger: false,
  logoutTrigger: false,
  flag: false,
  error: false
}

export const triggerLogoutAction = () => ({
  type: TRIGGER_LOGOUT
})

export const triggerSignInOrSignUpAction = () => ({
  type: TRIGGER_SIGN_IN_OR_UP
})

export const errorUponLoginOrSignupAction = payload => ({
  type: TRIGGER_SIGN_IN_OR_UP_FAILURE,
  payload
})

export const TriggerSignInOrSignUpReducer = (state = INITIAL_STATE, action = null) => {
  switch (action.type) {
    case TRIGGER_SIGN_IN_OR_UP: {
      return {
        ...state,
        trigger: true,
        logoutTrigger: false,
        flag: true,
        error: false,
      }
    }
    case TRIGGER_LOGOUT: {
      const webAuth = new auth0.WebAuth({
        domain: process.env.auth0.domain,
        clientID: process.env.auth0.clientId,
        audience: process.env.auth0.audience,
      });

      webAuth.logout({
        returnTo: process.env.redirectToOntosight,
        clientID: process.env.auth0.clientId,
      });
      removeCookies()
      return {
        ...state,
        trigger: false,
        logoutTrigger: true,
        flag: true,
        error: false,
      }
    }
    case TRIGGER_SIGN_IN_OR_UP_FAILURE: {
      return {
        ...state,
        trigger: false,
        flag: false,
        error: true,
        logoutTrigger: false,
      }
    }
    default: {
      return state
    }
  }
};

// add registered user from referral
const ADD_REGISTERED_USER_FROM_REFERAL_CODE = 'ADD_REGISTERED_USER_FROM_REFERAL_CODE'
const ADD_REGISTERED_USER_FROM_REFERAL_CODE_FAILURE = 'ADD_REGISTERED_USER_FROM_REFERAL_CODE_FAILURE'
const ADD_REGISTERED_USER_FROM_REFERAL_CODE_SUCCESS = 'ADD_REGISTERED_USER_FROM_REFERAL_CODE_SUCCESS'

const INITAL_STATE_ADD_REGISTERED_USER_FROM_REFERAL_CODE = {
  data: {},
  loading: false,
  error: false,
  flag: false
}

export const addRegisteredUserAction = (payload, params) => ({
  type: ADD_REGISTERED_USER_FROM_REFERAL_CODE,
  payload,
  params
})

const addRegisteredUserSuccess = payload => ({
  type: ADD_REGISTERED_USER_FROM_REFERAL_CODE_SUCCESS,
  payload
})

export const addRegisteredUserEpic = action$ => action$
  .ofType(ADD_REGISTERED_USER_FROM_REFERAL_CODE)
  .mergeMap(action => staticAjax(apiCall(`${process.env.faceliftApiUrl}v1/referral_program/add_registered_user?${action.payload.query}`, 'POST', true, action.payload.body))
    .map(response => addRegisteredUserSuccess(response))
    .catch(error => Observable.of({
      type: ADD_REGISTERED_USER_FROM_REFERAL_CODE_FAILURE,
      payload: error
    }, {
      type: ERROR,
      payload: error
    })))

export const addRegisteredUserReducer = (state = INITAL_STATE_ADD_REGISTERED_USER_FROM_REFERAL_CODE, action = null) => {
  switch (action.type) {
    case ADD_REGISTERED_USER_FROM_REFERAL_CODE: {
      return {
        ...state,
        data: {},
        loading: true,
        error: false,
        flag: false
      }
    }
    case ADD_REGISTERED_USER_FROM_REFERAL_CODE_SUCCESS: {
      return {
        ...state,
        data: action.payload.response.data,
        loading: false,
        error: false,
        flag: true,
      }
    }
    case ADD_REGISTERED_USER_FROM_REFERAL_CODE_FAILURE: {
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: true,
        flag: false
      }
    }
    default:
      return state
  }
}
