
import { Observable } from 'rxjs/Observable'
import { ajax as staticAjax } from 'rxjs/observable/dom/ajax'
import 'rxjs/add/operator/mergeMap'
import 'rxjs/add/operator/map'
import 'rxjs/add/operator/catch'
import 'rxjs/add/operator/takeUntil'
import 'rxjs/add/observable/of'
import { apiCall } from '../../../common/utils'
import { ERROR } from '../../../common/container/Status/logic'

// Request Access
export const REQUEST_ACCESS = 'REQUEST_ACCESS'
export const REQUEST_ACCESS_SUCCESS = 'REQUEST_ACCESS_SUCCESS'
export const REQUEST_ACCESS_CANCEL = 'REQUEST_ACCESS_CANCEL'
export const REQUEST_ACCESS_FAILURE = 'REQUEST_ACCESS_FAILURE'

const INITIAL_STATE = {
  data: {},
  loading: false,
  error: false,
  flag: false
}

export const requestAccessAction = payload => ({
  type: REQUEST_ACCESS,
  payload
})

export const requestAccessSuccess = payload => ({
  type: REQUEST_ACCESS_SUCCESS,
  payload
})

export const requestAccessCancel = payload => ({
  type: REQUEST_ACCESS_CANCEL,
  payload
})

export const requestAccessEpic = action$ => action$
  .ofType(REQUEST_ACCESS)
  .mergeMap(action => staticAjax(apiCall(`${process.env.faceliftApiUrl}v0/auth/requestaccess?`, 'POST', true, action.payload.body))
    .map(response => requestAccessSuccess(response))
    .takeUntil(action$.ofType(REQUEST_ACCESS_CANCEL))
    .catch(error => Observable.of({
      type: REQUEST_ACCESS_FAILURE,
      payload: error
    }, {
      type: ERROR,
      payload: error
    })))

export function requestAccessReducer (state = INITIAL_STATE, action = null) {
  switch (action.type) {
    case REQUEST_ACCESS: {
      return {
        ...state,
        data: {},
        loading: true,
        error: false,
        flag: false
      }
    }
    case REQUEST_ACCESS_SUCCESS: {
      return {
        ...state,
        data: action.payload.response.data,
        loading: false,
        error: false,
        flag: true
      }
    }
    case REQUEST_ACCESS_FAILURE: {
      return {
        ...state,
        data: {},
        loading: false,
        error: true,
        flag: false
      }
    }
    default:
      return state
  }
}
