import React from 'react'
import { FormattedMessage } from 'react-intl';

const textForInfluence = 'Ontosight® Explore is an AI-based engine for exploration and identification of obvious and non-obvious connections between diseases, pathways, proteins/targets, and drugs. It helps to visualize a comprehensive network of biological knowledge to better understand disease pathophysiology and underlying molecular connections to uncover novel mechanisms of action (MoA) or explore avenues for drug label expansion or combination therapies. Ontosight® Explore leverages artificial intelligence (AI) and life sciences ontology to generate continuous insights across discovery and pre-clinical phase of drug development.'

const keyFeatures = [
  'Strong and weak connections between 300K diseases, 6.5K pathways, 500K proteins, and 2M+ drugs & chemical compounds.',
  'Network graphs displaying associations which are up to three degrees of separation away, to link a disease to a drug based on its label, or indirect connection of a disease to a drug based on secondary targets, and pathways, which play a role in that disease.',
  'Accelerate and identify unexplored territory in drug discovery, drug repurposing, combination therapy and target identification efforts.',
  'Life science specific ontology for concept-based contextual and relevant search results.',
  'Real-time updated data from various scientific resources including 1M theses and dissertations, 35M publications, 580K clinical trials, 3M congress articles, 20M researchers & physicians, 6K regulatory updates, 5M grants etc. The tool also leverages associations between 300K diseases, over 2M chemical & drugs, 9M genes and 500K proteins etc.',
]

const useCases = [
  'Explore various direct and in-direct connections/known and unknown associations, and identify alternative entities and accelerate your drug discovery process to associated compounds and targets assisting in search of potential drugs and targets.',
  'Identify potential targets for a given pathway to treat specific diseases, or identify suitable therapeutic targets based on drugability, & competitive intelligence.',
  'Save blockbuster drugs to become generic by identifying new purposes, i.e. identification of new drug-alternative indications for any given drug based on the clinical data and network modularity.',
  'Identify diseases where a drug has the potential to treat, or identify similar/analogue drugs for any given diseases which could be further prioritized based on drug likeness score.',
  'Prioritize the drugs based on "association score",drugability, druglikeness and entity popularity.',
  'Significantly reduce preclinical study time and cost.',
]

const PremiumForExplore = () => (
  <div className='modal-premium' style={{ margin: '20px' }} >
    <div className="modal-header">
      <h3 className="modal-title text-center"> <span className="premium-icon" /><FormattedMessage id="You have discovered a Premium Feature" defaultMessage="You have discovered a Premium Feature" /></h3>
    </div>
    <div className="modal-body scrollbar" style={{ height: '500px', overflow: 'auto' }}>
      <div className="txt">
        <p className="text-center">You are trying to access <span className='make-bold'>Ontosight</span><sup className='make-bold'>®</sup><span className='make-bold'> Explore</span> that is not included in your Ontosight® Lite subscription</p>
        <p className='make-bold'>Ontosight® Explore</p>
        <p className='make-justify shift-right' >{textForInfluence}</p>
        <p className='make-bold' style={{ margin: 0 }}>Key features</p>
        <ul className="list-points scrollbar">
          {keyFeatures.map((item, i) => (
            <li key={i} className='make-space'>
              <span className="tick-premium make-justify" />
              {item}
            </li>
          ))}
        </ul>
        <p className='make-bold'>Primary use cases</p>
        <ul className="list-points scrollbar">
          {useCases.map((item, i) => (
            <li key={i} className='make-space'>
              <span className="tick-premium make-justify" />
              {item}
            </li>
          ))}
        </ul>
        <div className="btn-wrap text-center">
          <a className="btn btn-primary primary btn-rounded" href="https://www.innoplexus.com/index.php/buy-now/" target="_blank" rel="noopener noreferrer"><FormattedMessage id="BUY NOW" defaultMessage="BUY NOW" /> </a>
        </div>
      </div>
    </div>
  </div>
)

export default PremiumForExplore
