
import { Observable } from 'rxjs/Observable'
import { ajax as staticAjax } from 'rxjs/observable/dom/ajax'
import 'rxjs/add/operator/mergeMap'
import 'rxjs/add/operator/map'
import 'rxjs/add/operator/catch'
import 'rxjs/add/operator/takeUntil'
import 'rxjs/add/observable/of'
import mixpanel from 'mixpanel-browser';
import auth0 from 'auth0-js';
import { apiCall, getCookie, j, setCookie, splitToChunks, checkPermission } from '../../../common/utils'
import { ERROR } from '../../../common/container/Status/logic'

import { formApps } from '../../../common/container/utils'

// Callback
export const callback = 'callback'
export const callback_SUCCESS = 'callback_SUCCESS'
export const callback_CANCEL = 'callback_CANCEL'
export const callback_FAILURE = 'callback_FAILURE'

const INITIAL_STATE = {
  data: {},
  loading: false,
  error: false,
  flag: false
}

export const callbackAction = payload => ({
  type: callback,
  payload
})

export const callbckSuccess = payload => ({
  type: callback_SUCCESS,
  payload
})

export const callbackCancel = payload => ({
  type: callback_CANCEL,
  payload
})

export const callbackEpic = action$ => action$
  .ofType(callback)
  .mergeMap(action => staticAjax(apiCall(`${process.env.faceliftApiUrl}v0/auth/callback?app=ontosight-lite`, 'GET', false, action.payload.headers, null, true))
    .map(response => callbckSuccess(response))
    .takeUntil(action$.ofType(callback_CANCEL))
    .catch(error => Observable.of({
      type: callback_FAILURE,
      payload: error
    }, {
      type: ERROR,
      payload: error
    })))

export function callbackReducer (state = INITIAL_STATE, action = null) {
  switch (action.type) {
    case callback: {
      return {
        ...state,
        data: {},
        loading: true,
        error: false,
        flag: false
      }
    }
    case callback_SUCCESS: {
      const webAuth = new auth0.WebAuth({
        domain: process.env.auth0.domain,
        clientID: process.env.auth0.clientId,
        audience: process.env.auth0.audience,
      })

      const bearer = `Bearer ${getCookie('accessToken')}`;
      const userName = action.payload.response.profile.id;
      const activeApps = formApps(action.payload.response.active_apps);
      const apps = j(activeApps)
      apps.forEach((app, z) => {
        if (app.childrenApps) {
          app.childrenApps.forEach((item, i) => {
            if (item.name === 'Discover' && action.payload.response.is_trial_login) {
              apps[z].childrenApps[i].status = 'active'
              apps[z].childrenApps[i].active = `${apps[z].childrenApps[i].active}&&trialUser=${bearer}`
            }
          })
        }
      })
      const chunkLength = Math.ceil(action.payload.response.permissions.length / 80)
      const permissions = splitToChunks(action.payload.response.permissions, chunkLength)
      permissions.forEach((item, i) => {
        setCookie(JSON.stringify(item), `permissions-${i}`)
      })
      setCookie(action.payload.response.isGuidedTourViewed, 'isGuidedTourViewed');
      setCookie(chunkLength, 'pChunks')
      setCookie(action.payload.response.refreshToken, 'refreshToken')
      setCookie(bearer, 'accessToken')
      setCookie(action.payload.response.profile.first_name, 'name')
      setCookie(action.payload.response.profile.last_name, 'lastName')
      setCookie(userName, 'userName')
      setCookie(action.payload.response.profile.organization || 'individual', 'org')
      setCookie(apps, 'activeApps')
      setCookie(action.payload.response.allowedDomain, 'allowedDomain')
      setCookie(action.payload.response.emailVerified, 'emailVerifiedRequestAccess')
      setCookie(action.payload.response.accessRequested, 'accessRequested')
      setCookie(action.payload.response.isGuidedTourViewed, 'isGuidedTourViewed')
      setCookie(action.payload.response.profile.login_count, 'login_count')
      setCookie(action.payload.response.role, 'path')
      if (action.payload.response.is_trial_login) {
        setCookie(true, 'trialUser')
        localStorage.setItem('expiry-trial-period', action.payload.response.expiry_time)
      }
      const Token = getCookie('accessToken').replace('Bearer', '').trim();
      webAuth.client.userInfo(Token, (err, user) => {
        if (err) {
          setCookie(false, 'pageLoadingAuth0');
          this.setState({
            pageLoading: false,
          })
          this.props.customMessage({
            message: 'Oops! There has been an issue. Re-try in some time.',
            status: 'error',
            title: 'Error'
          })
          return null;
        }
        if (user) {
          setCookie(user.full_name, 'userNameAuth0')
          setCookie(true, 'userLoggedIn')
          setCookie(user.email_verified, 'emailVerified')
          setCookie(user.email, 'userEmail')
          mixpanel.identify(`${action.payload.response.profile.id}@@${checkPermission('ontosight_lite_user') ? `academics${getCookie('org') ? `##${getCookie('org')}` : ''}` : getCookie('org') || 'individual'}`)
          if (JSON.stringify(action.payload.response.role) !== JSON.stringify([])) {
            window.location.href = `${process.env.redirectUrl}requestaccess`
          }
          if (user.email_verified) {
            setCookie(false, 'pageLoadingAuth0');
            if (action.payload.response.profile) {
              window.location.href = `${process.env.routeHomepage}search`
            }
          } else if (!action.payload.response.allowedDomain && (action.payload.response.accessRequested === 'pending' || action.payload.response.accessRequested === 'In Progress')) {
            setCookie(false, 'pageLoadingAuth0');
            window.location.href = `${process.env.redirectUrl}requestaccess`
          } else {
            setCookie(false, 'pageLoadingAuth0');
            if (action.payload.response.profile.login_count === 1) {
              setCookie(true, 'firstTime')
              window.location.href = `${process.env.redirectUrl}verify`
            } else {
              window.location.href = `${process.env.redirectUrl}verify`
            }
          }
        }
        return undefined;
      });
      return {
        ...state,
        data: action.payload.response,
        loading: false,
        error: false,
        flag: true
      }
    }
    case callback_FAILURE: {
      return {
        ...state,
        data: {},
        loading: false,
        error: true,
        flag: false
      }
    }
    default:
      return state
  }
}
