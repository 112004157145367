import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { parse, stringify } from 'query-string'
import { injectIntl } from 'react-intl'
import LikeDislike from '../../container/LikeDislike'
import { setAnalytics, generateHashUrl, parseHashUrl } from '../../utils'
import { Mixpanel } from '../../utils/mixpanel';
import { sidOpenable } from '../../constant'

const ListIcons = (props) => {
  const query = parse(window.location.hash)
  const hash = parse(window.location.search)

  const appLabel = process.env.dashboard ? query.appLabel || hash.appLabel || process.env.appLabel : process.env.appLabel
  const q = generateHashUrl({
    dataset: props.data.type,
    id: props.data.doc_id,
    noBack: true,
    ...(appLabel ? { appLabel } : {})
  })
  let link = ''
  if (props.data && props.data['Source Link']) {
    const ref = props.data['Source Link'].match(/^https?:/) ? props.data['Source Link'] : `http://${props.data['Source Link']}`
    let params = sidOpenable.indexOf(props.data.type) > -1 ? {
      sid: 'ontosight'
    } : {}
    const split = ref.split('?')
    if (split.length > 1) {
      params = {
        ...params,
        ...(parse(split[1]) || '')
      }
    }
    link = `${split[0]}?${stringify(params)}`
  }

  const style = { display: 'flex' }
  return (
    <Fragment>
      {/* <div className='overlay' /> */}
      <div className='card-action-menu' style={style}>
        {props.takeFeedback && <LikeDislike {...props} data={props.data} likeParams={props.likeParams} />}
        {link && (
          <a
            title={props.intl.formatMessage({ id: 'Source link', defaultMessage: 'Source link' })}
            onClick={(e) => {
              Mixpanel.track('sourceLinkEvent', {
                eventData: JSON.stringify({ link, ...(props.data && props.data.type ? { type: props.data.type } : {}), ...(process.env.current === 'app' && parseHashUrl(props).summaryView === '1' ? { page: 'Analytics' } : { page: process.env.backLabel }) }),
              });
              setAnalytics('sourceLinkTrigger', 'sourceLink', JSON.stringify({ link, ...(props.data && props.data.type ? { type: props.data.type } : {}), ...(process.env.current === 'app' && parseHashUrl(props).summaryView === '1' ? { page: 'Analytics' } : { page: process.env.backLabel }) }));
              e.stopPropagation()
            }}
            rel="noopener noreferrer"
            className='web-link'
            target='_blank'
            href={link}
          />)}
        {props.data && props.data.type && props.data.doc_id && props.clickCheck.indexOf(props.data.type) > -1 && <a title={props.intl.formatMessage({ id: 'Open details in new tab', defaultMessage: 'Open details in new tab' })} onClick={(e) => { e.stopPropagation() }} rel="noopener noreferrer" className='abstract-new-tab' target='_blank' href={`/abstract?${q}`} />}
      </div>
    </Fragment>
  )
}

ListIcons.propTypes = {
  data: PropTypes.object.isRequired,
  likeParams: PropTypes.object,
  takeFeedback: PropTypes.bool,
  intl: PropTypes.object.isRequired,
  clickCheck: PropTypes.array,
  tourStatus: PropTypes.bool.isRequired
}

ListIcons.defaultProps = {
  likeParams: '',
  takeFeedback: true,
  clickCheck: []
}

export default withRouter(injectIntl(ListIcons))
