import { Observable } from 'rxjs/Observable'
import { ajax as staticAjax } from 'rxjs/observable/dom/ajax'
import 'rxjs/add/operator/mergeMap'
import 'rxjs/add/operator/map'
import 'rxjs/add/operator/catch'
import 'rxjs/add/observable/of'
import { apiCall } from '../../../common/utils'
import { ERROR } from '../../../common/container/Status/logic'

const DELETE_ACCOUNT = 'DELETE_ACCOUNT'
export const DELETE_ACCOUNT_SUCCESS = 'DELETE_ACCOUNT_SUCCESS'
const DELETE_ACCOUNT_FAILURE = 'DELETE_ACCOUNT_FAILURE'

const INITIAL_STATE = {
  data: [],
  loading: false,
  error: false,
  flag: false
}

export const deleteAccountAction = payload => ({
  type: DELETE_ACCOUNT,
  payload
})

const deleteAccountSuccess = payload => ({
  type: DELETE_ACCOUNT_SUCCESS,
  payload
})

export const deleteAccountEpic = action$ => action$
  .ofType(DELETE_ACCOUNT)
  .mergeMap(() => staticAjax(apiCall(`${process.env.faceliftApiUrl}v0/users/deactivate?app=ontosight-lite`, 'PUT', true, {}))
    .map(response => deleteAccountSuccess(response))
    .catch(error => Observable.of({
      type: DELETE_ACCOUNT_FAILURE,
      payload: error
    }, {
      type: ERROR,
      payload: error
    })))

export function deleteAccountReducer (state = INITIAL_STATE, action = null) {
  switch (action.type) {
    case DELETE_ACCOUNT: {
      return {
        ...state,
        data: [],
        loading: true,
        error: false,
        flag: false
      }
    }
    case DELETE_ACCOUNT_SUCCESS: {
      return {
        ...state,
        data: action.payload.response.data,
        loading: false,
        error: false,
        flag: true
      }
    }
    case DELETE_ACCOUNT_FAILURE: {
      return {
        ...state,
        data: [],
        loading: false,
        error: true,
        flag: false
      }
    }
    default:
      return state
  }
}
