import React from 'react'
import { FormattedMessage } from 'react-intl'
import { parse } from 'query-string'
import { withRouter } from 'react-router-dom'
import { getCookie } from '../../utils'
import { Mixpanel } from '../../utils/mixpanel'

const Footer2 = () => {
  const viewFooter = window.location.pathname === '/lite'
  const year = new Date().getFullYear()
  const query = parse(window.location.hash).alreadyLoggedIn
  const hash = parse(window.location.search).alreadyLoggedIn
  if (!getCookie('accessToken') && (query === 'true' || hash === 'true')) {
    return null
  }

  const handleContactClick = () => {
    // href='/contactus'
    Mixpanel.track('Contact us page accessed', {
      from: 'footer',
      buttonName: 'contact'
    })
    window.location.replace('/contactus')
  }

  return (
    <div>
      {!getCookie('accessToken') && !viewFooter && window.location.pathname !== '/contactus' && window.location.pathname !== '/research' &&
        <section className='support-sec'>
          <div className='support-list'>

            <div className='hiring'>
              <div className='support-title'>
                <FormattedMessage id="text.we-are-hiring" defaultMessage="We are Hiring!" />
              </div>
              <a href="https://jobs.innoplexus.com/" target="blank" className='btn-careers'><FormattedMessage id="link.careers" defaultMessage="Careers" /></a>
            </div>
            <div className="ast-builder-html-element">
              <div className='support-title' style={{ textAlign: 'left' }}>
                <FormattedMessage id="text.our-office" defaultMessage="Our Offices" />
              </div>
              <div className="footer-sec-row-first first" style={{ paddingLeft: '0' }}>
                <h4>Frankfurt (Germany)</h4>
                <div className="text-in-cont">Innoplexus AG<br />
                  Frankfurter Strasse 27,<br />
                  65760 Eschborn
                </div>
              </div>
              <div className="footer-sec-row-first second">
                <h4>Pune (India)</h4>
                <div className="text-in-cont">Innoplexus Consulting Services Pvt. Ltd.<br />
                  7th Floor, Midas Tower,<br />
                  Hinjewadi Phase 1 - Pune 57
                </div>
              </div>
              <div className="footer-sec-row-first third">
                <h4>Iselin (USA)</h4>
                <div className="text-in-cont">Innoplexus Holdings, Inc.<br />
                  33 Wood Avenue South,<br />
                  Suite 600, Iselin,<br />
                  NJ 08830
                </div>
              </div>
              <div className="footer-sec-row-first fourth">
                <h4>Cham (Switzerland)</h4>
                <div className="text-in-cont">Amrit AG<br />
                  Maschinengasse 12<br />
                  6330 Cham
                </div>
              </div>
            </div>

            {/* <div className='cust-support'>
              <div className='support-title'>
                <FormattedMessage id="text.customer-support" defaultMessage="Customer Support" />
              </div>
              <div className='customer-support-container customer-support-landing' >
                {
                  support.map((item, index) => (
                    <div key={index} role="presentation" className="customer-support-item" >
                      <div className='flag-media'>
                        <i style={{ display: 'block' }} className={item.logo} /><span className='flag-title'>{item.title}</span>
                      </div>
                      <div className='flag-count'>
                        <span className='contact-number'>{item.number}</span>
                      </div>
                    </div>
                  ))
                }
              </div>
            </div> */}
            <div className='media-links'>
              <div className='follow-us'>
                <div className='support-title'>
                  <FormattedMessage id="text-follow-us" defaultMessage="Follow us" />
                </div>
                <div className='social-link'>
                  <a className='linkdin icon' href='https://www.linkedin.com/company/innoplexus/' target='_blank' rel="noopener noreferrer" />
                  {/* <a className='pint icon' href='https://in.pinterest.com/innoplexus/' target='_blank' rel="noopener noreferrer" /> */}
                  <a className='twitter icon' href='https://twitter.com/Innoplexus' target='_blank' rel="noopener noreferrer" />
                  <a className='fb icon' href='https://www.facebook.com/innoplexus' target='_blank' rel="noopener noreferrer" />
                  <a className='ytube icon' href='https://www.youtube.com/user/Innoplexus' target='_blank' rel="noopener noreferrer" />
                </div>
              </div>
              <div className='write-us'>
                <div className='support-title'>
                  <FormattedMessage id="text-write-to-us" defaultMessage="Write to us" />
                </div>
                <div className='support-email'>
                  <a className='email-link' href="mailto:Support@innoplexus.com">Support@innoplexus.com</a>
                </div>
              </div>
            </div>
          </div>
        </section>
      }
      <footer className={`footer ${!getCookie('accessToken') ? 'footer-without-login' : ''} ${window.location.pathname === '/lite' ? 'liteFooter' : ''}`}>
        <div className='container footer-align'>
          <div className='logo-with-link'>
            <a href='https://www.innoplexus.com/' target='_blank' rel="noopener noreferrer" className='footer-innoplexus-logo' />
            <div className='footer-menus'>
              <div onClick={() => handleContactClick()} role='presentation' className='link-footer-menu'>
                <FormattedMessage id="link.contact" defaultMessage="Contact" />
              </div>
              <a href='/privacypolicy' className='link-footer-menu'>
                <FormattedMessage id="link.privacy-policy" defaultMessage="Privacy Policy" />
              </a>
              <a href='/userpolicy' className='link-footer-menu'>
                <FormattedMessage id="link.user-policy" defaultMessage="User Policy" />
              </a>
            </div>
          </div>
          <div className='copy'>
            {/* © {year} - <a href='http://www.innoplexus.com' target='blank'><FormattedMessage id="Innoplexus AG" defaultMessage="Innoplexus AG" /></a> - <FormattedMessage id="All Rights Reserved." defaultMessage="All Rights Reserved." /> */}
            <span>
              © {year} - <a className='link-footer-menu last-txt' target='_blank' rel="noopener noreferrer" href='https://www.innoplexus.com/'> Innoplexus<sup>&reg;</sup></a>
            </span>
            { viewFooter && <span className='freepik-text'>Illustrations design made by Freepik from https://storyset.com/</span>}
          </div>
        </div>
      </footer>
    </div>
  )
}


Footer2.propTypes = {
  // location: PropTypes.object.isRequired
}


export default withRouter(Footer2)
