import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import auth0 from 'auth0-js';
import { connect } from 'react-redux'
import { FormattedMessage, injectIntl } from 'react-intl'
import { stringify, parse } from 'query-string'
import { ClickOutside } from 'reusable-react-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark, faCircleXmark } from '@fortawesome/free-solid-svg-icons'
import { getCookie, getRedirectUrl, ucFirst, setAnalytics, checkPermission, checkBrowserSupport, parseHashUrl, setCookie, removeCookies } from '../../utils'
import { Mixpanel } from '../../utils/mixpanel';
import { setDataLang, LangSet } from '../../lang/logic'
import { SimpleDropDown, Loader } from '../../components'
import { drawerAction } from '../Drawer/logic'
import { modalAction } from '../Modal/logic'
import CustomerSupport from '../CustomerSupport'
import { DiscoverLinks } from '../constants'
import { getApps, getParticularApp } from '../utils'
import CountDown from '../CountDown'
import { guidedTourTriggerAction, researchProjectLedgerAction, gerReferralLinkAction, verifyReferallMailsAction, referNowAction } from './logic'
import { customMessage } from '../Status/logic'


const open = {
  type: 'notification',
  open: true
}

const close = {
  type: '',
  open: false
}

const backUrlCheck = url => [
  `/${process.env.current}/notifications`, `/${process.env.current}/subscriptions`, '/guidelinewatch/details', '/guidelinewatch/comparison'
].indexOf(url)

const renderApps = (props, fromdash) => {
  const notToShowForLiteUser = [
    'Marketplace',
    'Discover'
  ]

  if (getCookie('accessToken')) {
    let appName = 'Discover Lite'
    if (process.env.dashboard) {
      appName = ''
      let { appLabel = '' } = process.env
      appLabel = parse(window.location.search).appLabel || parseHashUrl(props).appLabel || appLabel
      const apps = getParticularApp('Dashboards')
      if (appLabel) {
        apps.forEach((app) => {
          if (appLabel === app.name) {
            appName = app.name
          }
        });
      }
    }

    if (fromdash === 'true' && checkPermission('competitive_landscape_jnj')) {
      appName = 'Competitive Landscape';
    }

    const handleDropdownClick = (app) => {
      switch (app) {
        case 'Data as a Service':
          props.modalAction({
            type: 'premium-data-as-science',
            open: true,
            dialog: true,
            size: 'large',
            data: ''
          })
          break;
        case 'Sentiment Watch':
          props.modalAction({
            type: 'premium-sentiment-watch',
            open: true,
            dialog: true,
            size: 'large',
            data: ''
          })
          break;
        case 'Competitive Landscape':
          props.modalAction({
            type: 'premium-competitive-landscape',
            open: true,
            dialog: true,
            size: 'large',
            data: ''
          })
          break;
        case 'Guideline Watch':
          props.modalAction({
            type: 'premium-guideline-watch',
            open: true,
            dialog: true,
            size: 'large',
            data: ''
          })
          break;
        case 'Regulatory Updates':
          props.modalAction({
            type: 'premium-regulatory-updates',
            open: true,
            dialog: true,
            size: 'large',
            data: ''
          })
          break;
        case 'Medical Affairs':
          props.modalAction({
            type: 'premium-medical-affaris',
            open: true,
            dialog: true,
            size: 'large',
            data: ''
          })
          break;
        case 'Influence':
          props.modalAction({
            type: 'premium-influence',
            open: true,
            dialog: true,
            size: 'large',
            data: ''
          })
          break;
        case 'Explore':
          props.modalAction({
            type: 'premium-explore',
            open: true,
            dialog: true,
            size: 'large',
            data: ''
          })
          break;
        default:
          props.modalAction({
            type: 'premium',
            open: true,
            dialog: true,
            size: 'large',
            data: ''
          })
      }
    }

    const renderDropdownValues = (app, j, i) => {
      if (checkPermission('ontosight_lite_user') && notToShowForLiteUser.includes(app.name)) {
        return null
      }

      return (
        checkPermission('ontosight_lite_user') && !(app.status === 'active') ?
          <a className={`unified-apps-dropdown-content-link ${app.name === appName ? 'active' : ''}`} key={`${i}-${j}-app-dropdown`} role='presentation' onClick={() => handleDropdownClick(app.name)} >{`${app.name}`}</a> :
          <a className={`unified-apps-dropdown-content-link ${app.name === appName ? 'active' : ''}`} key={`${i}-${j}-app-dropdown`} href={app[app.status]} >{`${app.name}`}</a>
      )
    }

    const Apps = getApps()
    return Apps.map((item, i) => {
      const childApps = getParticularApp(item.name)
      if (checkPermission('ontosight_lite_user') && notToShowForLiteUser.includes(item.name)) {
        return null
      }
      return (
        <li key={i} className={`left-nav-group-item ${(item.name === 'Dashboards' && checkPermission('competitive_landscape_jnj') && fromdash === 'true') ? 'active' : item.name === 'Search & Explore' && !process.env.dashboard && !checkPermission('competitive_landscape_jnj') ? 'active' : item.name === 'Dashboards' && process.env.dashboard ? 'active' : ''} unified-apps-dropdown`}>
          <a {...(childApps && childApps.length && childApps.length === 1 ? { href: childApps[0][childApps[0].status] } : {})} className='nav-link-item' >
            {item.name}
          </a>
          {childApps && !!childApps.length && childApps.length > 1 && (
            <div className='unified-apps-dropdown-content' >
              {childApps.map((app, j) => (
                  renderDropdownValues(app, j, i)
              ))}
            </div>
          )}
        </li>
      )
    })
  }
  return null
}

class UnifiedHeader extends Component {
  constructor (props) {
    super(props)
    this.state = {
      lang: this.props.lang,
      langTitle: this.props.lang === 'de' ? 'Deutsch' : (this.props.lang === 'fr' ? 'French' : 'English'),
      modelOpenRewardPoint: false,
      modelOpenShowRewards: false,
      dataRewardPoint: [
        { Contribution: 'Registration', Point: '100 points' },
        { Contribution: 'Search', Point: '5 points per search' },
        { Contribution: 'Feedback: Review with less than 200 characters', Point: '10 points per review' },
        { Contribution: 'Feedback: Review with more than 200 characters', Point: '50 points per review' },
        { Contribution: 'Opening articles', Point: '1 point per article' },
        { Contribution: 'Go to source for any document', Point: '1 point' },
        { Contribution: 'Exploring clinical trial details', Point: '5 points per “view detail”' },
        { Contribution: 'Bookmarks/Bibliography', Point: '3 points per bookmark' },
        { Contribution: 'Thumbs up/down', Point: '5 points per thumbs up/down' },
        { Contribution: 'Annotations', Point: '3 points per annotation' },
        { Contribution: 'Exploring Curated Views (Gene, Protein, Intervention, Author)', Point: '5 points - free view 25 points - paid view' },
        { Contribution: 'Referral', Point: '25 points per referral' },
        { Contribution: 'Word Cloud clicks', Point: '1 point per interaction' },
        { Contribution: 'Research Cluster', Point: '5 points per research cluster' }
      ],
      referrelMailList: [],
      referrelLastEmail: '',
      referrelModalOPen: false,
      emailError: '',
      textChangeCopy: 'Copy Link',
      dataReferallEmails: [],
      referralButtonDissable: true,
      notValidMails: [],
      alreadyExitsError: '',
      referral_link: ''
    }
    this.isBrowserSupported = checkBrowserSupport()
  }
  // componentDidMount() {
  //   if (getCookie('accessToken') && ((checkPermission('marketplace_permission') && !checkPermission('ontosight_lite_user')) || (process.env.standaloneNotifications && checkPermission(`${process.env.current}_notification_permission`)))) {
  //     this.props.notificationCountAction()
  //   }
  // }

  componentWillReceiveProps = (nextProps) => {
    if (this.props.lang !== nextProps.lang) {
      this.setState({
        langTitle: nextProps.lang === 'de' ? 'Deutsch' : (nextProps.lang === 'fr' ? 'French' : 'English')
      })
    }

    if (!this.props.referralLink.flag && nextProps.referralLink.flag) {
      this.setState({
        referral_link: nextProps.referralLink.data.referral_link
      })
    }

    if (this.props.verifyReferallMailStatus.loading && !nextProps.verifyReferallMailStatus.loading) {
      this.setState({
        dataReferallEmails: [
          ...nextProps.verifyReferallMailStatus.data
        ]
      })
      nextProps.verifyReferallMailStatus.data.forEach((item) => {
        if (item.alreadyexist) {
          this.setState({
            alreadyExitsError: 'The email shown in green are already in use'
          })
        } else {
          this.setState({
            referralButtonDissable: false
          })
        }
      })
    }

    if (!this.props.referNowStatus.flag && nextProps.referNowStatus.flag) {
      if (nextProps.referNowStatus.data.message === 'Email sent successfully') {
        this.props.customMessage({
          message: 'Referral Mail sent successfully',
          status: 'success',
          type: 'updateHistory',
          title: 'Success'
        })
      }
    }
  };

  onLangSelection = (lang) => {
    let langCode;
    let langTitle;
    switch (lang) {
      case 'Deutsch': {
        langCode = 'de';
        langTitle = 'Deutsch';
        break;
      }
      case 'French': {
        langCode = 'fr';
        langTitle = 'French';
        break;
      }
      default: {
        langCode = 'en';
        langTitle = 'English'
      }
    }
    if (langCode !== this.state.lang) {
      this.setState(() => ({
        lang: langCode,
        langTitle
      }));
      this.props.LangSet(langCode);
      if (langCode !== this.props.dataLang) {
        this.props.setDataLang(langCode);
      }
    }
  };

  onNotificationClick() {
    if (process.env.standaloneNotifications && checkPermission(`${process.env.current}_notification_permission`)) {
      const backUrl = `${window.location.origin}${window.location.pathname}?${stringify({ ...parse(window.location.search) })}`
      const url = getRedirectUrl(process.env.current, {}, {}, false)
      const params = stringify({
        ...(backUrlCheck(this.props.location.pathname) === -1 ? { backUrl } : { backUrl: parse(this.props.location.search).backUrl || backUrl }),
        backApp: process.env.backLabel
      })
      window.location = `${url}/notifications?${params}`
    } else {
      this.props.drawerAction(this.props.drawer.open ? close : open)
    }
  }

  onSubAppsClick(url, permission) {
    if (permission) {
      this.props.modalAction({
        type: 'premium',
        open: true,
        dialog: true,
        size: 'large'
      })
      Mixpanel.track('noPermissionEvent', {
        action: 'discover sub app click',
        permission: 'no permission',
        url,
      });
      setAnalytics('noPermissionTrigger', 'noPermission', JSON.stringify({
        url,
        permission: 'no permission',
        view: 'discover sub app click'
      }))
    } else {
      window.location.href = url;
    }
  }

  onSolutionSelection = (value) => {
    window.location.href = `${process.env.mainAppUrl}/${value.toLowerCase()}`
  }

  openModal(invite = false) {
    if (invite) {
      setAnalytics('rollingOutInvitesClickedTrigger');
      this.props.modalAction({
        type: 'request_invite',
        open: true,
        dialog: true,
        size: 'small'
      })
    } else {
      this.props.modalAction({
        type: 'login',
        open: true,
        dialog: true,
        size: 'medium'
      })
    }
  }

  webAuth = new auth0.WebAuth({
    domain: process.env.auth0.domain,
    clientID: process.env.auth0.clientId,
    audience: process.env.auth0.audience,
  });
  onSwitchToEnterprise = () => {
    const apps = getApps();
    const childApps = apps.filter(itm => itm.name === 'Search & Explore');
    const discoverApp = childApps[0].childrenApps.filter(app => app.name === 'Discover')
    setTimeout(() => {
      if (discoverApp[0].status === 'active') {
        window.location.replace(discoverApp[0].active);
      } else {
        this.props.modalAction({
          type: 'premium',
          open: true,
          dialog: true,
          size: 'large'
        })
      }
    }, 500);
  }
  renderLocalApps() {
    if (window.location.pathname === '/abstract') {
      return null
    }
    return DiscoverLinks(this.props.location.pathname).map((item, i) => {
      if (item.permission) {
        return (
          <li key={i} className={`left-nav-group-item ${item.active ? 'active' : ''}`}>
            <a role='presentation' onClick={() => this.onSubAppsClick(item.link, item.academic)} className='nav-link-item' >
              {item.academic && <span className='card-premium padding-sm-right' />}
              <div className='workspace-text'>
                <FormattedMessage id={item.id} defaultMessage={item.name} />
              </div>
            </a>
          </li>
        )
      }
      return null
    })
  }
  renderLanguage() {
    const { intl } = this.props
    const languagesOption = ['english', 'Deutsch', 'French'];
    if ((getCookie('accessToken') && checkPermission('language_localization')) || !getCookie('accessToken')) {
      return (
        <li className='language-item right-nav-group-item capitalize-me'>
          <SimpleDropDown
            DropdownClass='has-arrow'
            options={languagesOption}
            title={intl.formatMessage({ id: this.state.langTitle || 'abc', defaultMessage: this.state.langTitle })}
            onSelect={index => this.onLangSelection(languagesOption[index])}
          />
        </li>
      )
    }
    return null
  }
  renderAcademic() {
    const { intl } = this.props
    if (!getCookie('accessToken')) {
      return (
        <li className='right-nav-group-item'>
          <a title={intl.formatMessage({ id: 'Academic access', defaultMessage: 'Academic access' })} style={{ color: '#ffffff' }} href='/academic'><FormattedMessage id="Academic Access" defaultMessage="Academic Access" /></a>
        </li>
      )
    }
    return null
  }
  renderNotification() {
    if (getCookie('accessToken') && process.env.dashboard !== 1 && ((checkPermission('marketplace_permission') && !checkPermission('ontosight_lite_user')) || (process.env.standaloneNotifications && checkPermission(`${process.env.current}_notification_permission`)))) {
      const count = process.env.standaloneNotifications && checkPermission(`${process.env.current}_notification_permission`)
        ? this.props.notification.count.unread_count
        : this.props.count.data
      const { intl } = this.props
      return (
        <li className='right-nav-group-item notification-drawer'>
          <a title={intl.formatMessage({ id: 'Notifications', defaultMessage: 'Notifications' })} role='presentation' className='notification-icon-wrap' onClick={() => this.onNotificationClick()} >
            <i className='notification-icon' />
            {count > 0 && <span className='badge-count'>{count}</span>}
          </a>
        </li>
      )
    }
    return null
  }
  renderProfile() {
    if (getCookie('accessToken')) {
      const { intl } = this.props;
      const userOptions = [
        {
          name: <FormattedMessage id="Header.FAQ's" defaultMessage="FAQ's" />,
          title: intl.formatMessage({ id: 'Header.Frequently asked questions', defaultMessage: 'Frequently asked questions' }),
          cb: () => {
            Mixpanel.track('pageFromWhereFaqsClickedEvent', {
              eventData: this.props.location.pathname,
            });
            setAnalytics('pageFromWhereFaqsClickedTrigger', 'pageFromWhereFaqsClicked', this.props.location.pathname);
            window.location = `${process.env.mainAppUrl}/faq`
          }
        },
        {
          name: <FormattedMessage id="Header.Profile" defaultMessage="Profile" />,
          title: intl.formatMessage({ id: 'Header.More about you', defaultMessage: 'More about you' }),
          cb: () => { window.location = `${process.env.mainAppUrl}/profile` },
          condition: !getCookie('trialUser')
        },
        {
          name: <FormattedMessage id="Header.AccountSettings" defaultMessage="Account Settings" />,
          title: intl.formatMessage({ id: 'Header.Check / Update your settings', defaultMessage: 'Check / Update your settings' }),
          cb: () => { window.location = `${process.env.mainAppUrl}/settings` },
          condition: !getCookie('trialUser')
        },
        {
          name: <FormattedMessage id="Header.Subscriptions" defaultMessage="Subscriptions" />,
          cb: () => {
            const backUrl = `${window.location.origin}${window.location.pathname}?${stringify({ ...parse(window.location.search) })}`
            const url = getRedirectUrl(process.env.current, {}, {}, false)
            const params = stringify({
              ...(backUrlCheck(this.props.location.pathname) === -1 ? { backUrl } : { backUrl: parse(this.props.location.search).backUrl || backUrl }),
              backApp: process.env.backLabel
            })
            window.location = `${url}/subscriptions?${params}`
          },
          title: intl.formatMessage({ id: 'Header.Check / Update your subscriptions', defaultMessage: 'Check / Update your subscriptions' }),
          condition: !!process.env.standaloneNotifications && !!checkPermission(`${process.env.current}_notification_permission`) && !getCookie('trialUser'),
        },
        // { name: 'On Boarding', cb: () => { window.location = `${getRedirectUrl('app', {}, {}, false)}marketplace/#/home/onboarding/affiliations/` } },
        {
          name: 'Guided Tour',
          title: 'Guided Tour',
          cb: () => {
            this.props.guidedTourTriggerAction()
            setCookie(true, 'infoModelOpen')
            setCookie(window.location.href, 'previousRoute')
            window.location.replace(process.env.routeHomepage)
          }
        },
        {
          name: <FormattedMessage id="Header.Logout" defaultMessage="Logout" />,
          title: intl.formatMessage({ id: 'Header.See you next time!', defaultMessage: 'See you next time!' }),
          cb: () => {
            this.webAuth.logout({
              returnTo: process.env.redirectToOntosight,
              clientID: process.env.auth0.clientId,
            });
            Mixpanel.track('logoutEvent', {
              eventData: getCookie('userName'),
            });
            setAnalytics('logoutTrigger', 'logout', getCookie('userName'));
            removeCookies()
            window.location.href = process.env.auth0url
          }
        }
      ]
      const filteredUserOptions = userOptions.filter(opt => !('condition' in opt) || opt.condition)
      const profileIcon = `<div title='${ucFirst(getCookie('name') || 'Guest')}' class='disp-flex vcenter' ><div class='user-img'>
      <img class='user-face' src='/src/assets/images/svg/users.svg' alt='Profile' />
      </div><div class='nav-item text-elips'>${(getCookie('userNameAuth0') !== 'null' ? getCookie('userNameAuth0') : 'Guest')}</div></div>`
      return (
        <li className='user-item' >
          <SimpleDropDown
            DropdownClass='has-arrow'
            options={filteredUserOptions}
            title={profileIcon}
            onSelect={(index) => { filteredUserOptions[index].cb() }}
          />
        </li>
      )
    }
    return null
  }
  renderLogin() {
    if (!getCookie('accessToken')) {
      return (
        <li role='presentation' onClick={() => this.openModal()} className='right-nav-group-item'>
          <FormattedMessage id="LOGIN" defaultMessage="LOGIN" />
        </li>
      )
    }
    return null
  }
  renderAccessNow() {
    const style = {
      margin: '0 10px',
      cursor: 'pointer',
      fontSize: 16,
      color: '#2d2b2b9c'
    }
    if (!getCookie('accessToken')) {
      return (
        <li style={style} role='presentation' onClick={() => this.openModal(true)} className='right-nav-group-item'>
          Access Now
        </li>
      )
    }
    return null
  }
  renderSolution() {
    const Options = [...(this.props.location.pathname === '/lite' ? ['Discover Lite'] : []), 'Influence', 'Explore', 'Dashboards', 'Integrate', 'Daas'];
    return (
      <div className='solutions-item right-nav-group-item'>
        <SimpleDropDown
          // DropdownClass='has-arrow'
          options={Options}
          title='Solutions'
          onSelect={index => this.onSolutionSelection(Options[index])}
        />
      </div>
    )
  }
  renderHeaderLogout() {
    const query = parse(window.location.hash).alreadyLoggedIn
    const hash = parse(window.location.search).alreadyLoggedIn
    const style = {
      ...!getCookie('accessToken') && (query === 'true' || hash === 'true') ? { display: 'none' } : {}
    }
    return (
      <div style={style} className='unified-header-logged-out unified-header-top-container'>
        <ul className='left-nav-group'>
          <li className='nav-logo-wrap' >
            <a href={process.env.mainAppUrl} className='logo' >
              <i className='icon icon-logo-iplexus' />
            </a>
          </li>
          {renderApps(this.props)}
        </ul>
        <div className='menu-landing'>
          <div className='dropdown'>
            {this.renderSolution()}
          </div>
          <div className='dropdown'>
            <div className='dropbtn'>
              <CustomerSupport />
            </div>
          </div>
        </div>
        <ul className='right-nav-group'>
          {this.renderAccessNow()}
        </ul>
      </div>
    )
  }
  renderRewrds = () => {
    if (this.state.modelOpenShowRewards) {
      return (
        <div>
          <div className='reusable-overlay' />
          <ClickOutside onClickOutside={() => this.setState({ modelOpenShowRewards: false })} >
            <div className='reusable-modal reusable-modal-medium' style={{ marginTop: '135px', width: '860px', height: 'auto' }}>
              <Loader height='500px' loading={this.props.rewardPointsData.loading} error={this.props.rewardPointsData.error}>
                {
                  this.props.rewardPointsData.data && this.props.rewardPointsData.data.data &&
                  <div className='reward-point-modal'>
                    <div className='header-part'>
                      <div className='reward-point-header'>My Research Points</div>
                      <div className='reword-points-trigger-cross' role='presentation' onClick={() => this.setState({ modelOpenShowRewards: false })} >&#10005;</div>
                    </div>
                    <div className='container-total-points'>
                      <div className='show-points'>
                        <span className='text-points'>Total Points</span>
                        <div className='container-points'>
                          <span className='points'>{this.props.rewardPointsData.data.totaRpSum}</span>
                        </div>
                        <div className='show-message'>Soon, these research points will be used to access Ontosight<sup>®</sup> premium features.</div>
                      </div>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <span className='show-rewards-point-container'>
                        {
                    this.props.rewardPointsData.data.data.map(item => (
                      <Fragment>
                        <div className='show-rewards-details'>
                          {item.activity_count > 1 &&
                            <div className='mult-count'>{`x${item.activity_count}`}</div>
                          }
                          <div>
                            <div className='reward-header'>{item.activity_display_name}</div>
                            <div><span className='reward-point'>{item.research_point}</span><span className='reward-pt-text'><sup style={{ position: 'relative', left: '3px', bottom: '9px' }}>pts.</sup></span></div>
                          </div>
                        </div>
                      </Fragment>
                    ))
                  }
                      </span>
                    </div>
                  </div>}
              </Loader>
            </div>
          </ClickOutside>
        </div>
      )
    }
    return null
  }

  showRefferelMails = () => {
    if (this.state.referrelMailList.length === 0) {
      return this.state.referrelLastEmail
    }
    return null
  }

  handleReferrelEmailEnter2 = (event) => {
    event.preventDefault();
    const email = event.target.value

    const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (email.includes(',')) {
      const items = email.split(',');
      const emails = []
      const tempNOtVAlidMails = []
      items.forEach((item) => {
        if (item === '') {
          return null;
        }
        if (emailRegex.test(item.trim())) {
          this.setState({
            referrelMailList: [
              ...this.state.referrelMailList,
              item.trim()
            ],
            referrelLastEmail: '',
          })
          emails.push(item.trim())
        } else {
          tempNOtVAlidMails.push(item.trim())
          this.setState({
            emailError: 'Please enter a valide email',
            referrelLastEmail: '',
          })
        }
        this.setState({
          notValidMails: [
            ...this.state.notValidMails,
            ...tempNOtVAlidMails
          ],
        })
        return undefined;
      })
      if (emails.length > 0) {
        this.props.verifyReferallMailsAction({
          body: [
            ...this.state.referrelMailList,
            ...emails
          ]
        })
      }
    } else if (email.includes(' ')) {
      const items = email.split(' ');
      const emails = []
      const tempNOtVAlidMails = []
      items.forEach((item) => {
        if (item === '') {
          return null;
        }
        if (emailRegex.test(item.trim())) {
          this.setState({
            referrelMailList: [
              ...this.state.referrelMailList,
              item.trim()
            ],
            referrelLastEmail: '',
          })
          emails.push(item.trim())
        } else {
          tempNOtVAlidMails.push(item.trim())
          this.setState({
            emailError: 'Please enter a valide email',
            referrelLastEmail: '',
          })
        }
        return undefined;
      })
      this.setState({
        notValidMails: [
          ...this.state.notValidMails,
          ...tempNOtVAlidMails
        ],
      })
      if (emails.length > 0) {
        this.props.verifyReferallMailsAction({
          body: [
            ...this.state.referrelMailList,
            ...emails
          ]
        })
      }
    } else {
      this.setState({
        referrelLastEmail: email
      })
    }
    event.stopPropagation();
  }

  handleDelete = (key, color) => {
    if (color === 'green' || color === 'blue') {
      let flagGreen = true
      const newList = []
      const newList1 = []
      let referNowTrigger = false;
      this.state.dataReferallEmails.forEach((item) => {
        if (item !== key) {
          newList.push(item)
          if (item.alreadyexist) {
            flagGreen = false
          }
        } else if (!item.alreadyexist) {
          referNowTrigger = true
        }
      })
      this.state.referrelMailList.forEach((item) => {
        if (item !== key.email) {
          newList1.push(item)
          if (item.alreadyexist) {
            flagGreen = false
          }
        }
      })
      this.setState({
        dataReferallEmails: [
          ...newList
        ],
        referrelMailList: [
          ...newList1
        ],
        referralButtonDissable: referNowTrigger
      })
      if (flagGreen) {
        this.setState({
          alreadyExitsError: ''
        })
      }
    } else if (color === 'red') {
      const newList = this.state.notValidMails.filter(item => item !== key)
      this.setState({
        notValidMails: [
          ...newList
        ]
      })
      if (newList.length === 0) {
        this.setState({
          emailError: ''
        })
      }
    }
  }

  showEmails = () => {
    if (this.state.dataReferallEmails.length > 0) {
      return this.state.dataReferallEmails.map((item) => {
        if (item.alreadyexist) {
          return (
            <span className='email-check-green'>{item.email}
              <FontAwesomeIcon onClick={() => this.handleDelete(item, 'green')} icon={faXmark} style={{ color: '#000000', marginLeft: '4px' }} />
            </span>)
        }
        return (
          <span className='email-check-blue'>{item.email}
            <FontAwesomeIcon onClick={() => this.handleDelete(item, 'blue')} icon={faXmark} style={{ color: '#000000', marginLeft: '4px' }} />
          </span>)
      })
    }
    return null;
  }

  showNotValidEmails = () => {
    if (this.state.notValidMails.length > 0) {
      return this.state.notValidMails.map(item => (
        <span className='email-check-red'>{item}
          <FontAwesomeIcon onClick={() => this.handleDelete(item, 'red')} icon={faXmark} style={{ color: '#000000', marginLeft: '4px' }} />
        </span>))
    }
    return null
  }

  handleReferrelEmailEnter = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      const email = this.state.referrelLastEmail
      const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      const item = email.trim();
      const emails = []
      const tempNOtVAlidMails = []
      if (item === '') {
        return null;
      }
      if (emailRegex.test(item)) {
        this.setState({
          referrelMailList: [
            ...this.state.referrelMailList,
            item
          ],
          referrelLastEmail: '',
        })
        emails.push(item)
      } else {
        tempNOtVAlidMails.push(item)
        this.setState({
          emailError: 'Please enter a valide email',
          referrelLastEmail: '',
        })
      }
      this.setState({
        notValidMails: [
          ...this.state.notValidMails,
          ...tempNOtVAlidMails
        ],
      })
      if (emails.length > 0) {
        this.props.verifyReferallMailsAction({
          body: [
            ...this.state.referrelMailList,
            ...emails
          ]
        })
      }
      event.stopPropagation();
    }
    return undefined
  }

  handleReferralFormSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation()
  }

  handleReferResearcherClick = () => {
    this.props.gerReferralLinkAction()
    this.setState({ referrelModalOPen: true })
  }

  handleReferNowClick = () => {
    const validEmails = []
    this.state.dataReferallEmails.forEach((item) => {
      if (!item.alreadyexist) {
        validEmails.push(item.email)
      }
    })
    this.props.referNowAction({
      body: validEmails
    })
    this.handleDefaultState();
  };

  handleDefaultState = () => {
    this.setState({
      referrelMailList: [],
      referrelLastEmail: '',
      referrelModalOPen: false,
      emailError: '',
      textChangeCopy: 'Copy Link',
      dataReferallEmails: [],
      referralButtonDissable: true,
      notValidMails: [],
      alreadyExitsError: ''
    })
  }

  handleReferLinkCopyClick = () => {
    navigator.clipboard.writeText(this.props.referralLink.data.referral_link);
    this.setState({ textChangeCopy: 'Copied' })
  }

  renderReferrelModal = () => {
    if (this.state.referrelModalOPen && this.props.referralLink.flag) {
      return (
        <div>
          <div className='reusable-overlay'>
            <ClickOutside onClickOutside={() => this.handleDefaultState()} >
              <div
                className='reusable-modal reusable-modal-medium'
                style={{
                    width: '900px',
                    height: 'aut0',
                    marginTop: '150px'
              }}
              >
                <Loader height='500px' loading={this.props.referralLink.loading} error={this.props.referralLink.error}>
                  <div className='referrel-container'>
                    <div className='reword-points-trigger-2' role='presentation' onClick={() => this.handleDefaultState()} ><FontAwesomeIcon icon={faCircleXmark} style={{ color: '#000000', width: '20px', height: '20px' }} /></div>
                    <div className='header-text-refer'>Refer a Researcher & Earn Rewards!</div>
                    <div className='subheader-text-refer-1'>For every researcher you refer, you will get 25 points which you can use to
                    </div>
                    <div className='subheader-text-refer-2'>access Ontosight’s premium features!</div>
                    <div>
                      <form className='form-referrel' onSubmit={this.handleReferralFormSubmit}>
                        <label>
                          <div className='container-email-input'>
                            <div className='show-emails'>
                              <Loader height='10px' loading={this.props.verifyReferallMailStatus.loading} >
                                {this.showEmails()} {this.showNotValidEmails()}
                              </Loader>
                            </div>
                            <input
                              className='email-input'
                              placeholder='Enter your friends emails (seperated by comamas)'
                              onKeyDown={this.handleReferrelEmailEnter}
                              onChange={this.handleReferrelEmailEnter2}
                              value={this.state.referrelLastEmail}
                            />
                          </div>
                          <Loader height='40px' loading={this.props.verifyReferallMailStatus.loading} >
                            {this.state.alreadyExitsError.length > 0 &&
                            <div className='email-error-green'>{this.state.alreadyExitsError}</div>
                            }
                            {this.state.emailError.length > 0 &&
                            <div className='email-error'>{this.state.emailError}</div>
                            }
                          </Loader>
                        </label>
                        <div className='display-button'>
                          <button disabled={this.state.referralButtonDissable} className='refer-now-button' onClick={() => this.handleReferNowClick()}>Refer Now</button>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div className='referrel-container-2'>
                    <div className='header-text-refer'>Share my referral link with researcher</div>
                    <div className='container-email-input'>
                      {this.state.referral_link}
                    </div>
                    <div className='display-button'>
                      <button onClick={() => this.handleReferLinkCopyClick()}className='refer-now-button'>{this.state.textChangeCopy}</button>
                    </div>
                  </div>
                </Loader>
              </div>
            </ClickOutside>
          </div>
        </div>
      )
    }

    return null;
  }

  renderRewardsPointModel = () => {
    const modalFooterText = 'Soon, these reward points will be used to access Ontosight\'s premium features'
    if (this.state.modelOpenRewardPoint) {
      return (
        <div>
          <div className='reusable-overlay' />
          <ClickOutside onClickOutside={() => this.setState({ modelOpenRewardPoint: false })} >
            <div className='reusable-modal reusable-modal-medium' style={{ marginTop: '150px', width: '860px', height: 'auto' }}>
              <div className='reward-point-modal'>
                <div className='header-part'>
                  <div className='reward-point-header'>Research Champion - Points, levels, and badging</div>
                  <div className='reword-points-trigger' role='presentation' onClick={() => this.setState({ modelOpenRewardPoint: false })} ><FontAwesomeIcon icon={faCircleXmark} style={{ color: '#000000', width: '20px', height: '20px' }} /></div>
                </div>
                <div className='reward-points-table scrollbar'>
                  <table className='table'>
                    <tr>
                      <th className='left-part common head'>Type of contribution</th>
                      <th className='right-part common head'>Points earned per contribution</th>
                    </tr>
                    {this.state.dataRewardPoint.map(item => (
                      <tr>
                        <td className='left-part common'>{item.Contribution}</td>
                        <td className='right-part common'>{item.Point}</td>
                      </tr>
                ))}
                  </table>
                </div>
                <div><i>{modalFooterText}</i></div>
              </div>
            </div>
          </ClickOutside>
        </div>
      )
    }
    return null
  }

  renderHeaderLogin() {
    const fromdash = parse(window.location.search).fromDashboard
    const query = parse(window.location.hash).alreadyLoggedIn
    const hash = parse(window.location.search).alreadyLoggedIn
    const style = {
      ...!getCookie('accessToken') && (query === 'true' || hash === 'true') ? { display: 'none' } : {}
    }
    const permission = checkPermission('discover_permission') || checkPermission('myworkspace_permission') /* || checkPermission('marketplace_permission') */ || process.env.dashboard
    let appName = 'Discover'
    let appUrl = '/?alreadyLoggedin=true'
    if (checkPermission('ontosight_lite_user')) {
      appName = 'Discover Lite'
    } else if (process.env.dashboard) {
      appName = 'Dashboards'
      let appLabel = parse(window.location.search).appLabel || parseHashUrl(this.props).appLabel
      const discoverDashboardApps = ['competitive', 'competitivev1']
      if (process.env.current && discoverDashboardApps.indexOf(process.env.current) > -1) {
        appLabel = 'Competitive Landscape'
      }
      const apps = getParticularApp('Dashboards')
      if (appLabel) {
        apps.forEach((app) => {
          if (appLabel === app.name) {
            appName = app.name
            appUrl = app.active
          }
        });
      }
    } else if (fromdash === 'true' && checkPermission('competitive_landscape_jnj')) {
      const appLabel = 'Competitive Landscape';
      const apps = getParticularApp('Dashboards')
      apps.forEach((app) => {
        if (appLabel === app.name) {
          appName = app.name
          appUrl = app.active
        }
      });
    } else {
      appName = 'Discover Lite'
    }
    const logoUrl = process.env.dashboard ? appUrl : `${process.env.standalone ? getRedirectUrl(process.env.current, { alreadyLoggedIn: true }, null, false) : getRedirectUrl('app', { alreadyLoggedIn: true }, null, false)}`
    const localAppsLength = DiscoverLinks().length
    return (
      <Fragment>
        <div style={style} className='unified-header-top-container'>
          <ul className='left-nav-group'>
            <li className='nav-logo-wrap' >
              <a href={logoUrl} className='logo' >
                <i className='icon icon-logo-iplexus' />
              </a>
            </li>
            {renderApps(this.props, fromdash)}
            {this.renderRewardsPointModel()}
          </ul>
          <ul className='right-nav-group joyride-profile' >
            <li className='right-nav-group-item'>
              {!checkPermission('ontosight_lite_user') && <CustomerSupport />}
            </li>
            {this.renderAcademic()}
            {this.renderProfile()}
            {this.renderLogin()}
          </ul>
        </div>
        {getCookie('accessToken') && permission && this.isBrowserSupported &&
          <div className='unified-header-bottom-container'>
            <ul className='left-nav-group'>
              {/* <a href={appUrl} className={`${checkPermission('ontosight_lite_user') ? 'lite-user product-title' : 'product-title'}`}> */}
              <a href={appUrl} className='product-title' >
                {checkPermission('ontosight_lite_user') && <div className='lite-logo' />}
                {appName}
              </a>
              {!!localAppsLength && <span className='product-sep' />}
              {this.renderLocalApps()}
            </ul>
            <div className='workspace-text' />
            <ul className='right-nav-group'>
              {getCookie('trialUser') && (
                <li className='right-nav-group-item' >
                  <CountDown />
                </li>
              )}
              {this.renderLanguage()}
              <div
                className='enterprise-data-button'
                role="presentation"
                onClick={() => this.handleReferResearcherClick()}
              >
                Refer a Researcher
              </div>
              <div
                className='enterprise-data-button'
                role="presentation"
                onClick={() => this.setState({ modelOpenRewardPoint: true })}
              >
                Earn Rewards!
              </div>
              <div
                className='enterprise-data-button'
                role="presentation"
                onClick={() => this.onSwitchToEnterprise()}
              >
                Switch To Enterprise
              </div>
              {this.renderReferrelModal()}
              {this.renderRewrds()}
              {checkPermission('competitive_landscape_jnj') ? null : this.renderNotification()}
              <div
                className='reword-points-trigger'
                role='presentation'
                onClick={() => {
                  this.props.researchProjectLedgerAction();
                  this.setState({
                    modelOpenShowRewards: true,
                  });
                }}
                title="My Research Points"
              />
            </ul>
          </div>
        }
      </Fragment>
    )
  }
  render() {
    if (getCookie('accessToken')) {
      const remove = document.getElementById('header-container')
      if (remove) {
        remove.style.display = 'block'
      }
    }
    return (
      <header id='header-container' className='unified-header'>
        {getCookie('accessToken') ? this.renderHeaderLogin() : this.renderHeaderLogout()}
      </header>
    )
  }
}

UnifiedHeader.propTypes = {
  // triggerLogoutAction: PropTypes.func.isRequired,
  drawerAction: PropTypes.func.isRequired,
  drawer: PropTypes.object.isRequired,
  // notificationCountAction: PropTypes.func.isRequired,
  count: PropTypes.object.isRequired,
  modalAction: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  notification: PropTypes.object,
  // app: PropTypes.string,
  LangSet: PropTypes.func.isRequired,
  setDataLang: PropTypes.func.isRequired,
  lang: PropTypes.string.isRequired,
  dataLang: PropTypes.string.isRequired,
  intl: PropTypes.object.isRequired,
  guidedTourTriggerAction: PropTypes.func.isRequired,
  researchProjectLedgerAction: PropTypes.func.isRequired,
  rewardPointsData: PropTypes.object.isRequired,
  gerReferralLinkAction: PropTypes.func.isRequired,
  referralLink: PropTypes.object.isRequired,
  verifyReferallMailsAction: PropTypes.func.isRequired,
  referNowAction: PropTypes.func.isRequired,
  verifyReferallMailStatus: PropTypes.object.isRequired,
  referNowStatus: PropTypes.object.isRequired,
  customMessage: PropTypes.func.isRequired,
}
UnifiedHeader.defaultProps = {
  notification: {},
  app: 'ontosight-lite'
}

const mapStateToProps = state => ({
  drawer: state.drawer,
  count: state.count,
  notification: state.notificationList,
  lang: state.language.lang,
  dataLang: state.language.dataLang,
  rewardPointsData: state.rewardPointsData,
  referralLink: state.referralLink,
  verifyReferallMailStatus: state.verifyReferallMailStatus,
  referNowStatus: state.referNowStatus
})

export default withRouter(connect(mapStateToProps, {
  drawerAction, modalAction, setDataLang, LangSet, guidedTourTriggerAction, researchProjectLedgerAction, gerReferralLinkAction, verifyReferallMailsAction, referNowAction, customMessage
})(injectIntl(UnifiedHeader)))
